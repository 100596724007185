import { toast } from 'react-toastify';
import { alertConstants } from '../../constants/alert.constants';

export const alertActions = {
  success,
  error,
  clear,
  warning,
  info
};

function success(message) {
  toast.success(message); 
  return { type: alertConstants.SUCCESS, message };
}

function error(message,autoClose=true) {
  toast.error(message,{ autoClose: autoClose }); 
  return { type: alertConstants.ERROR, message };
}
function warning(message,autoClose=true) {
  toast.warning(message,{ autoClose: autoClose }); 
  return { type: alertConstants.WARRNING, message };
}
function info(message,autoClose=true) {
  toast.info(message,{ autoClose: autoClose }); 
  return { type: alertConstants.INFO, message };
}

function clear() {
  toast.dismiss(); 
  return { type: alertConstants.CLEAR };
}
