import React from "react";
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  progressBar: {
    width: '100%',
    marginRight: theme.spacing(1),
  },
  percentageBox: {
    minWidth: 35,
  },
  percentageText: {
    color: theme.palette.text.secondary,
  },
}));

const LinearProgressWithLabel = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.boxContainer}>
      <Box className={classes.progressBar}>
        <LinearProgress />
      </Box>
      <Box className={classes.percentageBox}>
        <Typography variant="body2" className={classes.percentageText}>
        <span style={{ whiteSpace: 'nowrap' }}>{props.word}</span>
        </Typography>
      </Box>
    </Box>
  );
};

export default LinearProgressWithLabel;
