import { handleResponse, handleError } from "../../services/handleError";
// import jwt from 'jwt-decode';
import config from "../../config";
import axios from "axios";
import { alertActions } from "./alert.actions";
import { DOWNLOAD_REQUEST, DOWNLOAD_SUCCESS, DOWNLOAD_FAILURE, STUDIES_REQUEST, STUDIES_SUCCESS, 
  STUDIES_FAILURE,GET_VIEWER_ID_REQUEST,GET_VIEWER_ID_SUCCESS ,GET_VIEWER_ID_FAILURE} from '../actionTypes/actionTypes';


export const studiesActions = {
  getAllStudies,
  downloadFolderAsZip,
  uploadReport,
  CompleteStudy,
  UpdateReferringPhysician,
  ApproveStudy,
  getOHIFViewerId,
  DownloadReport
}

function downloadFolderAsZip(folderName, patientId, view,studyInstanceUID) {
  return async (dispatch) => {
    dispatch({ type: DOWNLOAD_REQUEST });

    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        throw new Error("Authentication token not found");
      }
      const response = await axios.get(`${config.baseUrl}Upload/DownloadImagesAsZip`, {
        params: {
          folderName: folderName,
          patientId: patientId,
          view: view,
        },
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      let url = "";
      if (!view) {
        const { downloadUrl } = response.data;
        url = downloadUrl; 
      } else {
        url = `${config.orthanceUrl}stone-webviewer/index.html?study=${studyInstanceUID}`;
        window.open(url, '_blank');
        dispatch({ type: DOWNLOAD_SUCCESS });
        return true;
      }
      const link = document.createElement('a');
      link.href =window.location.origin+'/files/'+ url;
      link.setAttribute('download', url);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link); 

      dispatch({ type: DOWNLOAD_SUCCESS });
      return true;
    } catch (error) {
      console.error("Error downloading folder as zip:", error);
      dispatch({ type: DOWNLOAD_FAILURE, payload: error.message });
      return false;
    }
  };
}
function getOHIFViewerId(folderName, patientId, studyInstanceUID) {
  return async (dispatch) => {
    dispatch({ type: GET_VIEWER_ID_REQUEST });

    try {
      const token = sessionStorage.getItem('token');
      if (!token) {
        throw new Error("Authentication token not found");
      }
      const response = await axios.get(`${config.baseUrl}Upload/GetOHIFViewerId`, {
        params: {
          folderName: folderName,
          patientId: patientId,
          studyInstanceUID: studyInstanceUID,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      const { apiResult } = response.data;
      
      // Dispatch success action with the download URL
      dispatch({ type: GET_VIEWER_ID_SUCCESS, payload: apiResult });

      // Open the OHIF viewer URL in a new tab
      console.log("app result"+JSON.parse(apiResult)+" "+apiResult);
      if (apiResult && JSON.parse(apiResult)[0] != null) {
        window.open(`${config.orthanceUrl}ohif/viewer?url=../studies/${JSON.parse(apiResult)[0]["ID"]}/ohif-dicom-json`, '_blank');
      }
      return true;
    } catch (error) {
      console.error("Error fetching OHIF viewer ID:", error);
      dispatch({ type: GET_VIEWER_ID_FAILURE, payload: error.message });
      dispatch(alertActions.error("Failed to open OHIF viewer.",false));
      return false;
    }
  };
}


// Action creator for fetching studies
export function getAllStudies({
  PageNumber = 1,
  PageSize = 10,
  PatientId = "",
  PatientName = "",
  AccessionNumber = "",
  Modality = [],
  Branch = [],
  StartDate = "",
  EndDate = "",
  SortColumn = "",
  SortDirection = "ASC",
  Role = "",
  UserId = ""
}) {
  return async (dispatch) => {
    dispatch({ type: STUDIES_REQUEST });
    try {
      // Build query string with all parameters
      const queryParams = new URLSearchParams({
        pageNumber: PageNumber,
        pageSize: PageSize,
        PatientId: PatientId,
        PatientName: PatientName,
        AccessionNumber: AccessionNumber,
        Modality: Modality, // Convert array to comma-separated string
        StartDate: StartDate,
        EndDate: EndDate,
        Branch: Branch,
        SortColumn: SortColumn,
        SortDirection: SortDirection,
        Role: Role,
        UserId: UserId
      }).toString();
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        }
      };
      const response = await fetch(`${config.baseUrl}Study?${queryParams}`, requestOptions);
      const result = await handleResponse(response);

      dispatch({ type: STUDIES_SUCCESS, payload: result });
      return result;
    } catch (error) {
      dispatch({ type: STUDIES_FAILURE, payload: error });
      throw error; // Propagate the error
    }
  };
}
function uploadReport(file, branchId, customerName, patientCode, studyIncId, studyInstanceUID, userId, studyCode) {
  return async (dispatch) => {
    try {

      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileName', file.name);
      formData.append('customerName', customerName);
      formData.append('branchId', branchId);
      formData.append('patientCode', patientCode);
      formData.append('studyIncId', studyIncId);
      formData.append('studyInstanceUID', studyInstanceUID);
      formData.append('userId', userId);
      formData.append('studyCode', studyCode);

      const response = await axios.post(config.baseUrl + `Upload/UploadStudyDocument`, formData, {
        responseType: 'blob', // Ensure the response is treated as a binary blob
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        },
      });
      return true;
    } catch (error) {
      return false;
    }
  };
}
export function ApproveStudy(BranchName, StudyInstanceUID, studyCode, userId, patientCode) {
  return async (dispatch) => {
    try {

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        }
      };

      const response = await fetch(`${config.baseUrl}Study/ApproveStudy?folderName=${BranchName + '/' + patientCode + '/' + StudyInstanceUID}&id=${studyCode}&userId=${userId}`, requestOptions);
      const result = await handleResponse(response);
      return result;
    } catch (error) {
      dispatch(alertActions.error("Error While Report Completed Study, please check if word report is existed."));
    }
  };
}
export function CompleteStudy(studyCode, userId) {
  return async (dispatch) => {
    try {

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        }
      };

      const response = await fetch(`${config.baseUrl}Study/CompleteStudy/${studyCode}/${userId}`, requestOptions);
      const result = await handleResponse(response);
      return result;
    } catch (error) {
      throw error;
    }
  };
}
export function UpdateReferringPhysician(studyCode, userId, referringId, referringName) {
  return async (dispatch) => {
    try {

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: 'Bearer ' + sessionStorage.getItem('token')
        }
      };

      const response = await fetch(`${config.baseUrl}Study/UpdateReferringPhysician/${studyCode}/${userId}/${referringId}/${referringName}`, requestOptions);
      const result = await handleResponse(response);
      return result;
    } catch (error) {
      throw error;
    }
  };
}
function DownloadReport(model) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        config.baseUrl + `Upload/DownloadReport?folderName=${model.BranchName}/${model.patientCode}/${model.StudyInstanceUID}&studyCode=${model.StudyId}&isPDF=${model.isPDF}`,
        {
          responseType: 'blob', // Ensure the response is treated as a binary blob
          headers: {
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
          },
        }
      );

      const contentDisposition = response.headers['content-disposition'];
      let fileName = model.studyCode + (model.isPDF ? '.pdf' : '.docx');

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        if (fileNameMatch != null && fileNameMatch[1]) {
          fileName = fileNameMatch[1].replace(/['"]/g, '');
        }
      }

      // Detect file type from response headers if needed
      const fileType = response.headers['content-type'];
      let fileExtension = '.docx'; // Default extension

      if (fileType) {
        if (fileType.includes('pdf')) {
          fileExtension = '.pdf';
        } else if (fileType.includes('wordprocessingml')) {
          fileExtension = '.docx';
        }
      }

      // Adjust the file name to ensure it has the correct extension
      if (!fileName.endsWith(fileExtension)) {
        const baseFileName = fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
        fileName = baseFileName + fileExtension;
      }

      // Create a blob from the response data
      const file = new Blob([response.data], { type: fileType });
      const url = URL.createObjectURL(file);

      // Create a temporary link to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName; // Use the file name with the correct extension
      link.click();

      URL.revokeObjectURL(url);
      return true;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        dispatch(alertActions.error('File not found.'));
      } else if (error.response && error.response.status === 500) {
        dispatch(alertActions.error('Server error.'));
      } else {
        dispatch(alertActions.error('An unexpected error occurred.'));
      }
    }
  };
}
