import AllTemplatesPage from "./AllTemplatesPage";

export const AllTemplatesPageConfig = {
  routes: [
    {
      path: "/templates",
      exact: true,
      component: AllTemplatesPage,
      protected: true,
      roles:["Admin","Consultant","Specialist"],
    }
  ]
};
