import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { users } from "./users.reducer";
import {userListReducer} from "./userList.reducer";
import {downloadReducer} from "./download.reducer";
import { alertReducer } from './alert.reducer';

const rootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        users,
        downloadReducer,
        userListReducer,
        alert: alertReducer,
    });
export default rootReducer;