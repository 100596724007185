// import { MaterialUIComponentsNavigation } from "../pages/documentation/material-ui-components/MaterialUIComponentsNavigation";
import React from 'react';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import PeopleIcon from '@material-ui/icons/People'; // Import the People icon
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import PhoneIcon from '@material-ui/icons/Phone';
const navigationConfig = [
  {
    id: "Main",
    title: "MAIN",
    type: "group",
    children: [
      // {
      //   id: "dashboard",
      //   title: "Dashboard",
      //   type: "item",
      //   icon: "apps",
      //   url: "/",
      //   exact: true,
      // },
      {
        id: "studies",
        title: "Studies",
        type: "item",
        icon: <LocalHospitalIcon />,  // Icon for "Medical Studies"
        url: "/studies",
        roles:["Admin","Consultant","Specialist","Client","Referring Physician"],
        exact: true,
      },
      {
        id: "upload",
        title: "Upload",
        type: "item",
        icon: "upload-cloud",
        url: "/upload",
        roles:["Admin","Client"],
        exact: true,
      },
      {
        id: "templates",
        title: "Templates",
        type: "item",
        icon: <InsertDriveFileIcon />,
        url: "/Templates",
        roles:["Admin","Consultant","Specialist"],
        exact: true,
      },
      {
        id: "Reports",
        title: "Reports",
        type: "item",
        icon: "description",
        url: "/pages/about",
        roles:["Admin"],
        exact: true,
      },
      // {
      //   id: "calendar",
      //   title: "Calendar",
      //   type: "item",
      //   icon: "event",
      //   url: "/pages/calendar",
      //   exact: true,
      // },
    ],
  },
  
  {
    id: "Admin",
    title: "Admin",
    type: "group",
    roles:["Admin"],
    children: [

      {
        id: "customers",
        title: "HCO",
        type: "collapse",
        roles:["Admin"],
        icon: <PeopleIcon />,
        exact: true
        ,
        children: [
          {
            id: "all Customers",
            title: "All HCO",
            type: "item",
            url: "/HCO",
            roles:["Admin"],
            exact: true,
          },
          {
            id: "add customer",
            title: "Add HCO",
            type: "item",
            url: "/AddHCO",
            roles:["Admin"],
            exact: true,
          },
        ],
      },
      {
        id: "Authentication",
        title: "Authentication",
        type: "collapse",
        icon: "lock",
        exact: true,
        roles:["Admin"],
        children: [
          // {
          //   id: "Login",
          //   title: "Login",
          //   type: "item",
          //   url: "/login",
          //   exact: true,
          // },
          {
            id: "Users",
            title: "Users",
            type: "item",
            url: "/Users",
            roles:["Admin"],
            exact: true,
          },
          {
            id: "Register",
            title: "Add User",
            type: "item",
            url: "/register",
            roles:["Admin"],
            exact: true,
          },
          {
            id: "DeletedUsers",
            title: "Deleted Users",
            type: "item",
            url: "/DeletedUsers",
            roles:["Admin"],
            exact: true,
          },
          // {
          //   id: "ChangePassword",
          //   title: "Change Password",
          //   type: "item",
          //   url: "/ChangePassword",
          //   exact: true,
          // },
          // {
          //   id: "Forgot Password",
          //   title: "Forgot Password",
          //   type: "item",
          //   url: "/forgot-password",
          //   exact: true,
          // },
        ],
      },

    ],
  },
  {
    id: "contactUs",
    title: "Contact Us",
    type: "group",
    children: [
      {
        id: "support",
        title: "Support",
        type: "item",
        icon: <PhoneIcon  />,  
        url: "/Support",
        roles:["Admin","Consultant","Specialist","Client","Referring Physician"],
        exact: true,
      },
      // {
      //   id: "UTS-Viewer",
      //   title: "OHIF Viewer",
      //   type: "link",
      //   icon: "link",
      //   url: "https://www.utsportal.com:105/local",
      //   target: "_blank",
      //   roles:["Admin","Consultant","Specialist","Client","Referring Physician"],
      //   // badge: {
      //   //   title: "git",
      //   //   bg: "green",
      //   //   fg: "#FFFFFF"
      //   // }
      // }
    ],
  },
  {
    id: "divider-1",
    type: "divider",
  },
  // {
  //   id: "Documentation",
  //   title: "DOCUMENTATION",
  //   type: "group",
  //   children: [
  //     // {
  //     //   id: "Material UI Components",
  //     //   title: "Material UI Components",
  //     //   type: "collapse",
  //     //   icon: "layers",
  //     //   children: [...MaterialUIComponentsNavigation]
  //     // },
  //     {
  //       id: "UTS - Management",
  //       title: "UTS - Management",
  //       type: "link",
  //       icon: "link",
  //       url: "",
  //       exact: true,
  //     },
  //   ],
  // },
  // {
  //   id: "dashboard",
  //   title: "Dashboard",
  //   type: "item",
  //   icon: "dashboard",
  //   url: "/",
  //   exact: true
  // },
  // {
  //   id: "pages",
  //   title: "Pages",
  //   type: "group",
  //   icon: "pages",
  //   children: [
  //     {
  //       id: "all pages",
  //       title: "Pages",
  //       type: "collapse",
  //       icon: "stars",
  //       badge: {
  //         title: "new",
  //         bg: "#525E8A",
  //         fg: "#FFFFFF"
  //       },
  //       children: [
  //         {
  //           id: "about",
  //           title: "About",
  //           type: "item",
  //           icon: "info",
  //           url: "/pages/about",
  //           exact: true
  //         },
  //         {
  //           id: "contact",
  //           title: "Contact",
  //           type: "item",
  //           icon: "group",
  //           url: "/pages/contact",
  //           exact: true
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   id: "divider-1",
  //   type: "divider"
  // },

];

export default navigationConfig;
