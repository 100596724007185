import ResetPasswordPage from "./ResetPasswordPage";

export const ResetPasswordPageConfig = {
  routes: [
    {
      path: "/resetPassword",
      exact: true,
      component: ResetPasswordPage,
      protected: true,
      roles:['Admin']
    }
  ]
};
