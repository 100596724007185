import LoginPage from "./ForgotPasswordPage";

export const ForgotPasswordPageConfig = {
  routes: [
    {
      path: "/ForgotPassword",
      exact: true,
      component: LoginPage,
      protected: false,
      roles:['Admin',
        'Consultant',
        'Specialist',
        'Client']

    }
  ]
};
