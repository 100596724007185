import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, roles, isAuthenticated, ...rest }) => {
  const currentUser = JSON.parse(sessionStorage.getItem("user"));

  const isTokenExpired = () => {
    const token = sessionStorage.getItem("token");
    if (!token) return true; 
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const expirationTime = decodedToken.exp * 1000; 
    return Date.now() >= expirationTime;
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isTokenExpired()) {
          sessionStorage.removeItem("token"); 
          return <Redirect to="/login" />;
        }
        
        if (!isAuthenticated) {
          return <Redirect to="/login" />;
        }

        if (Component.name === "ChangePasswordPage") {
          return <Component {...props} />;
        }

        if (!roles || (currentUser.roles && roles.some(role => currentUser.roles.includes(role)))) {
          return <Component {...props} />;
        }

        return <Redirect to="/pages/errors/error-404" />;
      }}
    />
  );
};

export default ProtectedRoute;
