import React, { useState, useEffect } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Checkbox,
    FormControlLabel,
    FormGroup,
} from "@material-ui/core";

const ChangeColumnDialog = ({ open, onClose, columns }) => {
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [updatedColumns, setUpdatedColumns] = useState([]);
    useEffect(() => {
        if (open) {
            const initialColumns = columns.map(column => ({
                ...column,
                options: {
                    ...column.options,
                    display: column.options.display !== false 
                }
            }));
            setUpdatedColumns(initialColumns);
            setSelectedColumns(
                initialColumns
                    .filter(column => column.options.display)
                    .map(column => column.name)
            );
        }
    }, [open, columns]);

    const handleCheckboxChange = (name) => {
        setSelectedColumns((prev) => {
            const isChecked = prev.includes(name);
            const newColumns = updatedColumns.map(column => {
                if (column.name === name) {
                    return {
                        ...column,
                        options: {
                            ...column.options,
                            display: !isChecked 
                        }
                    };
                }
                return column;
            });
            setUpdatedColumns(newColumns);
            return isChecked ? prev.filter(column => column !== name) : [...prev, name];
        });
    };

    return (
        <Dialog open={open} onClose={()=>{
            onClose(columns);
        }}>
            <DialogTitle>Change Columns</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Select the columns you want to display:
                </DialogContentText>
                <FormGroup>
                    {updatedColumns.map((column) => {
                        const isChecked = selectedColumns.includes(column.name);
                        return (
                            <FormControlLabel
                                key={column.name}
                                control={
                                    <Checkbox
                                        checked={isChecked}
                                        onChange={() => handleCheckboxChange(column.name)}
                                    />
                                }
                                label={column.label}
                            />
                        );
                    })}
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                        onClose(columns);
                    }} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        onClose(updatedColumns);
                    }}
                    color="secondary"
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ChangeColumnDialog;
