// reducer.js
import { DOWNLOAD_REQUEST, DOWNLOAD_SUCCESS, DOWNLOAD_FAILURE } from '../actionTypes/actionTypes';

const initialState = {
    isLoading: false,
    data: null,
    error: null,
};

export function downloadReducer(state = initialState, action) {
    switch (action.type) {
        case DOWNLOAD_REQUEST:
            return { ...state, isLoading: true, error: null };
        case DOWNLOAD_SUCCESS:
            return { ...state, isLoading: false, data: action.payload };
        case DOWNLOAD_FAILURE:
            return { ...state, isLoading: false, error: action.payload };
        default:
            return state;
    }
  }
  
