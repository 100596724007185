import React from "react";
import { Redirect } from "react-router-dom";

import { DashboardPageConfig } from "../pages/dashboard/DashboardPageConfig";
import { AboutPageConfig } from "../pages/about/AboutPageConfig";
import { AddCustomerPageConfig } from "../pages/customers/add-customer/AddCustomerPageConfig";
import { AllCustomersPageConfig } from "../pages/customers/all-customers/AllCustomersPageConfig";
import {AllStudiesPageConfig} from "../pages/studies/all-studies/AllStudiesPageConfig";
import { AllTemplatesPageConfig } from "../pages/template/all-templates/AllTemplatesPageConfig";
import{UploadPageConfig} from "../pages/UploadPage/UploadPageConfig";
import {ContactUsConfig} from "../pages/Support/ContactUsConfig";
import { LoginPageConfig } from "../pages/auth/login/LoginPageConfig";
import {ForgotPasswordPageConfig} from "../pages/auth/ForgotPassword/ForgotPasswordPageConfig";
import {UsersPageConfig} from "../pages/auth/users/UsersPageConfig";
import { RegisterPageConfig } from "../pages/auth/register/RegisterPageConfig";
import {ChangePasswordPageConfig} from "../pages/auth/changePassword/ChangePasswordPageConfig"
import {DeletedUsersPageConfig} from "../pages/auth/DeletedUsers/DeletedUsersPageConfig";
import {ResetPasswordPageConfig} from "../pages/auth/resetPassword/ResetPasswordPageConfig";
import { Error404PageConfig } from "../pages/errors/404/Error404PageConfig";
import { Error500PageConfig } from "../pages/errors/500/Error500PageConfig";
// import { DocumentationConfig } from "../pages/documentation/DocumentationConfig";

const routeConfigs = [
  // ...DashboardPageConfig.routes,
  ...AllCustomersPageConfig.routes,
  ...AllStudiesPageConfig.routes,
  ...ContactUsConfig.routes,
  ...AddCustomerPageConfig.routes,
  ...AllTemplatesPageConfig.routes,
  // ...CalendarPageConfig.routes,
  ...UploadPageConfig.routes,
  ...DeletedUsersPageConfig.routes,
  ...ChangePasswordPageConfig.routes,
  ...LoginPageConfig.routes,
  ...ForgotPasswordPageConfig.routes,
  ...UsersPageConfig.routes,
  ...ResetPasswordPageConfig.routes,
  ...RegisterPageConfig.routes,
  ...Error404PageConfig.routes,
  ...Error500PageConfig.routes,
  // ...AboutPageConfig.routes
  // ...DocumentationConfig.routes
];

const routes = [
  ...routeConfigs,
  {
    component: () => <Redirect to="/pages/errors/error-404" />
  }
  // {
  //   path: "/test",
  //   exact: true,
  //   component: <Example />
  // }
];

export default routes;
