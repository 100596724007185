import React, { useState, useEffect } from "react";
import { userActions } from "../../../redux/actions/user";
import UTSLayout from "../../../components/UTSLayout/UTSLayout";
import UTSBreadcrumbs from "../../../components/UTSBreadcrumbs/UTSBreadcrumbs";
import { Typography, Grid, Button, makeStyles, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  my3: {
    margin: "1.3rem 0"
  },
  mb0: {
    marginBottom: 0
  },
  mRight: {
    marginRight: ".85rem"
  },
  p1: {
    padding: ".85rem"
  }
}));

const UsersPage = (props) => {
  const { history } = props;
  const classes = useStyles();
  const [isload, setIsload] = useState(false);
  const [Users, setUsers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const dispatch = useDispatch();
  const fetchData = async () => {
   
      try {
        let usersData = await dispatch(userActions.getAllUsers());
        setUsers(usersData);
        setIsload(true);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
  };
  const handleEdit = (rowIndex) => {
    const selectedRow = Users[rowIndex];
    history.push({ pathname: `/register`, state: { user: selectedRow } });
  };
  const handleResetPassword=(rowIndex)=>{
    const selectedRow = Users[rowIndex];
    history.push({ pathname: `/resetPassword`, state: { user: selectedRow } });
  }
  const handleDelete = (rowIndex) => {
    const selectedRow = Users[rowIndex];
    setSelectedUser(selectedRow);
    setOpenDialog(true);
  };

  const confirmDelete =async () => {
    let deletedUser = await dispatch(userActions.deleteUser(selectedUser.userId));
    fetchData();
    setOpenDialog(false);

  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedUser(null);
  };

  const columns = [
    {
      name: "userId",
      label: "userId",
      options: {
        filter: true,
        sort: true,
        display: false
      }
    },

    {
      name: "firstName",
      label: "First Name",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "lastName",
      label: "Last Name",
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: "roles",
      label: "Roles",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          
          return (
            value?.join(', ')
          )}
      }
    },
    {
      name: "customersDisplay",
      label: "HCO",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          
          return (
            value?.join(', ')
          )}
      }
    },
    // {
    //   name: "createdBy",
    //   label: "Created By",
    //   options: {
    //     filter: true,
    //     sort: true
    //   }
    // },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <IconButton
                color="primary"
                size="small"
                onClick={() => handleEdit(tableMeta.rowIndex)}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                color="secondary"
                size="small"
                onClick={() => handleDelete(tableMeta.rowIndex)}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                  style={{ color: "green" }} // Apply green color to the icon
                  size="small"
                  onClick={() => handleResetPassword(tableMeta.rowIndex)} // Adjust this function as needed
                >
                  <VpnKeyIcon />
                </IconButton>
            </>
          );
        }
      }
    }
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 15, 20],
  };

  useEffect(() => {
    if (!isload) {
    fetchData();
    }
  }, [isload, dispatch]);

  return (
    <UTSLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight}>
          <Typography variant="h5" component="h1">
            Users
          </Typography>
        </Grid>
        <Grid item>
          <Button
            onClick={() => history.push("/register")}
            variant="outlined"
            color="primary"
            size="small"
          >
            Add User
          </Button>
        </Grid>
      </Grid>
       
      <MUIDataTable
        title={"Users List"}
        data={Users}
        columns={columns}
        options={options}
      />

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </UTSLayout>
  );
};

export default UsersPage;
