import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  makeStyles,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  AccordionActions,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userActions } from "../../../../redux/actions/user";

const useStyles = makeStyles((theme) => ({
  mb3: {
    marginBottom: theme.spacing(3),
  },
  contactInfo: {
    padding: theme.spacing(3),
    maxWidth: "600px",
    margin: "auto",
  },
}));

const AddReferringPhysicianDialog = ({
  open,
  onClose,
  data = [{ id: 1, name: "Doctor" }],
  isSelectingFiles,
  physicianActions,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const historyRef = useHistory();
  const [user, setUser] = useState({});
  const [errors, setErrors] = useState([]);
  const [errorEmail, setEmailError] = useState('');
  const [errorFirstName, setFirstNameError] = useState('');
  const [errorLastName, setLastNameError] = useState('');
  const [errorConfirmationPassword, setConfirmationPasswordError] = useState('');
  const [errorPassword, setPasswordError] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state
  const currentUser = userActions.getLoginUser();

  const getUserData = (e) => {
    console.log('getUserData called with:', e);
    if (e && e.target) {
      const { name, value } = e.target;
      setUser((prevUser) => ({
        ...prevUser,
        [name]: value,
      }));
    } else {
      console.error('Event target is null or undefined', e);
    }
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors([]);
    if (validateForm()) {
      setLoading(true); // Set loading to true
      user.createdBy = currentUser.email;
      let result = null;
      try {
        user.roles=['Referring Physician'];
        user.customers=[currentUser.customers[0]];
        result = await dispatch(userActions.register(user));
        if (result?.succeeded) {
          onClose();
        } else {
          setErrors(result?.errors || []);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // Reset loading state
      }
    }
  };

  const validateForm = () => {
    let isValid = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Email validation
    if (!emailRegex.test(user.email || '')) {
      setEmailError('Please enter a valid email address.');
      isValid = false;
    } else {
      setEmailError('');
    }

    // Password and confirmation validation
    if (!user.userId && user.password !== user.confirmPassword) {
      setConfirmationPasswordError('Passwords do not match.');
      isValid = false;
    } else {
      setConfirmationPasswordError('');
    }

    // First name validation
    if (!user.firstName) {
      setFirstNameError('First Name is required.');
      isValid = false;
    } else {
      setFirstNameError('');
    }

    // Last name validation
    if (!user.lastName) {
      setLastNameError('Last Name is required.');
      isValid = false;
    } else {
      setLastNameError('');
    }

    // Password validation
    if (!user.userId && !user.password) {
      setPasswordError('Password is required.');
      isValid = false;
    } else {
      setPasswordError('');
    }

    return isValid;
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Referring Physician</DialogTitle>
      <DialogContent>

        {errors.length > 0 && (
          <Accordion expanded={true} className={classes.mb3}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Errors</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                {errors.map((error, index) => (
                  <Grid item xs={12} key={index}>
                    <Typography color="error">{error.description}</Typography>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}

        <Accordion expanded={true} className={classes.mb3}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>User: {user.firstName || ''}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="firstName"
                  label="First Name"
                  fullWidth
                  margin="normal"
                  value={user.firstName || ''}
                  onChange={getUserData}
                  error={!!errorFirstName}
                  helperText={errorFirstName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="lastName"
                  label="Last Name"
                  fullWidth
                  margin="normal"
                  value={user.lastName || ''}
                  onChange={getUserData}
                  error={!!errorLastName}
                  helperText={errorLastName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="email"
                  label="Email"
                  fullWidth
                  margin="normal"
                  value={user.email || ''}
                  onChange={getUserData}
                  error={!!errorEmail}
                  helperText={errorEmail}
                />
              </Grid>
              {!user.userId && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      id="passwordNew" // You can leave this as is
                      name="password" // Use a unique name
                      label="Password"
                      type="password"
                      fullWidth
                      margin="normal"
                      value={user.password || ''}
                      onChange={getUserData} // Directly calling the function
                      error={!!errorPassword}
                      helperText={errorPassword}
                      autoComplete="new-password" // Set to "new-password" to prevent autofill
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="passwordConfirmNew" // You can leave this as is
                      name="confirmPassword" // Use a unique name
                      label="Confirm Password"
                      type="password"
                      fullWidth
                      margin="normal"
                      value={user.confirmPassword || ''}
                      onChange={getUserData} // Directly calling the function
                      error={!!errorConfirmationPassword}
                      helperText={errorConfirmationPassword}
                      autoComplete="new-password" // Set to "new-password" to prevent autofill
                    />
                  </Grid>

                </>
              )}
            </Grid>
          </AccordionDetails>
          <Divider />
          <AccordionActions>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="primary" variant="contained" disabled={loading}>
              Save
            </Button>
          </AccordionActions>
        </Accordion>
      </DialogContent>
    </Dialog>
  );
};

export default AddReferringPhysicianDialog;
