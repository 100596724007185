import AllCustomersPage from "./AllCustomersPage";

export const AllCustomersPageConfig = {
  routes: [
    {
      path: "/HCO",
      exact: true,
      component: AllCustomersPage,
      protected: true,
      roles:['Admin']
    }
  ]
};
