import "./LoginPage.css";
import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { userActions } from "../../../redux/actions/user";
import MuiCard from "../../../components/extra/card/MuiCard";

const LoginPage = (props) => {
    const dashboardLinkRef = useRef(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const propsData = location.state;
    const [isLoad, setIsLoad] = useState(false);
    const [user, setUser] = useState({ password: '', email: '' });
    const [errorList, setErrorList] = useState([]);
    const [isDarkMode, setIsDarkMode] = useState(false);

    useEffect(() => {
        if ( sessionStorage.getItem('token') !== null) {
            history.push('/');
            window.location.reload();
        }
    }, [history]);

    const getUserData = (e) => {
        const { name, value } = e.target;
        setUser(prevUser => ({ ...prevUser, [name]: value }));
    };

    const onLogin = async (e) => {
        e.preventDefault();
        setIsLoad(true);
        const result = await dispatch(userActions.login(user));
        setIsLoad(false);
        if (result) {
            history.push('/');
            window.location.reload();
        } else {
            // Handle login error
            setErrorList([{ message: "Login failed. Please check your credentials." }]);
        }
    };

    if ( sessionStorage.getItem('token') != null) {
        history.push('/');
        window.location.reload();
    }

    return (
        <div className={`login ${isDarkMode ? 'darkMode' : ''}`}>
            <div className="loginContainer">
                <div className="mainDiv">
                    <MuiCard className={`cardStyle ${isDarkMode ? 'darkMode' : ''}`}>
                        <form onSubmit={onLogin}>
                            <img
                                style={{ width: '15rem' }}
                                src='https://unitedtelescans.com/wp-content/uploads/2024/07/LOGO-01-1-1.png'
                                alt="logo"
                                id="loginLogo"
                                onClick={() => window.location.href = 'https://unitedtelescans.com/'}
                            />
                            <h2 className={`formTitle ${isDarkMode ? 'darkMode' : ''}`}>
                                Welcome to <br /> United Tele Scans
                            </h2>
                            <hr className={`horizontalLine ${isDarkMode ? 'darkMode' : ''}`} />
                            <div className="inputDiv">
                                <label className={`inputLabel ${isDarkMode ? 'darkMode' : ''}`}>Email</label>
                                <input
                                    type="text"
                                    onChange={getUserData}
                                    name="email"
                                    autoComplete="off"
                                />
                            </div>
                            <div className="inputDiv">
                                <label className={`inputLabel ${isDarkMode ? 'darkMode' : ''}`}>Password</label>
                                <input
                                    type="password"
                                    onChange={getUserData}
                                    name="password"
                                    required
                                />
                            </div>
                            {/* Error Messages */}
                            {errorList.length > 0 && (
                                <div className="errorMessageDiv">
                                    {errorList.map((error, index) => (
                                        <div key={index} className="errorMessage">
                                            {error.message}
                                        </div>
                                    ))}
                                </div>
                            )}
                            <div className="buttonWrapper">
                                <button
                                    style={{
                                        background: 'linear-gradient(to right, #36648B 0%, #36648B 79%)',
                                        textTransform: 'uppercase',
                                        letterSpacing: '0.2rem',
                                        width: '65%',
                                        height: '3rem',
                                        border: 'none',
                                        color: 'white',
                                        borderRadius: '2rem',
                                        cursor: 'pointer'
                                    }}
                                    type="submit"
                                    className="submitButton pure-button pure-button-primary"
                                >
                                    {isLoad ? 'Loading...' : 'Login'}
                                </button>
                            </div>
                        </form>
                    </MuiCard>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
