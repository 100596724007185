import { alertConstants } from "../../constants/alert.constants";

const initialState = {
  type: null,
  message: null,
};

export function alertReducer(state = initialState, action) {
  switch (action.type) {

    case alertConstants.SUCCESS:
      return {
        type: 'success',
        message: action.message,
      };
    case alertConstants.ERROR:
      return {
        type: 'error',
        message: action.message,
      };
      case alertConstants.INFO:
        return {
          type: 'info',
          message: action.message,
        };
        case alertConstants.WARRNING:
          return {
            type: 'warring',
            message: action.message,
          };
    case alertConstants.CLEAR:
      return {
        type: null,
        message: null,
      };
    default:
      return state;
  }
}
