
import {handleResponse,handleError} from "../../services/handleError";
// import jwt from 'jwt-decode';
import config from "../../config";
import axios from "axios";


export const templatesActions = {
    uploadTemplate,
    EditTemplate,
    GetAllTemplates,
    DownloadTemplate,
    DeleteTemplate
}
function uploadTemplate(file) {
    return async (dispatch) => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('fileName', file.name);
            const response = await axios.post(config.baseUrl +`Template/UploadTemplate`,formData, {
                responseType: 'blob', // Ensure the response is treated as a binary blob
                headers: {
                    'content-type': 'multipart/form-data',
                    Authorization: 'Bearer ' +  sessionStorage.getItem('token'),
                  },
            });
    
            return true;
        } catch (error) {
            return false;
        }
    };
}
function EditTemplate(file, template) {
    return async (dispatch) => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('fileName', file.name);
            // Convert the template object to a JSON string and append it to the formData
            formData.append('template', JSON.stringify(template));

            const response = await axios.post(`${config.baseUrl}Template/EditTemplate`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' +  sessionStorage.getItem('token'),
                },
            });

            return true;
        } catch (error) {
            console.error('Error during EditTemplate:', error);
            return false;
        }
    };
}

  function GetAllTemplates() {
    return  async(dispatch) => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json",
                Authorization: 'Bearer ' +  sessionStorage.getItem('token')
            }
            
        };
        
        let result=  await fetch(config.baseUrl + "Template/GetAllTemplates", requestOptions).then(
            handleResponse,
            handleError
          );
           return result;
    };
}

function DeleteTemplate(templateId) {
    return  async(dispatch) => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json",
                Authorization: 'Bearer ' +  sessionStorage.getItem('token')
            }
            };
        
        let result=  await fetch(config.baseUrl + "Template/DeleteTemplate?TemplateId="+templateId, requestOptions).then(
            handleResponse,
            handleError
          );
           return result;
    };
}
function DownloadTemplate  (templateName,fileName=null) {
    return async (dispatch) => {
        try {
            if(fileName==null){
                fileName= templateName;
            }
            const response = await axios.get(config.baseUrl +`Template/DownloadTemplate?templateName=${templateName}`, {
                responseType: 'blob', // Ensure the response is treated as a binary blob
                headers: { 
                    Authorization: 'Bearer ' +  sessionStorage.getItem('token')
                }
            });
    
            const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
            const url = URL.createObjectURL(file);
    
            // Create a temporary link to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.download = `${fileName}`;
            link.click();
    
            URL.revokeObjectURL(url);
    
            return true;
        } catch (error) {
            return false;
        }
    };
}