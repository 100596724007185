import React, { useMemo, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { useDispatch } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Paper, MenuItem } from "@material-ui/core";
import InputLabel from '@material-ui/core/InputLabel';
import UTSLayout from "../../components/UTSLayout/UTSLayout";
import { customerActions } from "../../redux/actions/customer";
import UTSBreadcrumbs from "../../components/UTSBreadcrumbs/UTSBreadcrumbs";
import SelectingFilesTab from './SelectingFilesTab';
import UploadingTab from './UploadingTab';
import { UseUploadPage } from '../../redux/actions/useUploadPage';
import { userActions } from "../../redux/actions/user";
import { useStyles } from './styles';

export default function UploadPage(props) {
    const dispatch = useDispatch();
    const [data, setCustomers] = React.useState([]);
    const { history } = props;
    const classes = useStyles();
    const { isSelectingFiles, selectedFiles, selectedCustomer, handleOnSelectCustomer, handleOnSelectFiles, backOnSelectFiles } = UseUploadPage();
    const [isLoad, setIsLoad] = React.useState(false);
    const currentUser = userActions.getLoginUser();
    
    const renderTabs = useMemo(() => {
        switch (isSelectingFiles) {
            case true:
                return <SelectingFilesTab onSelectFiles={handleOnSelectFiles} />;
            default:
                return <UploadingTab selectedFiles={selectedFiles} backOnSelectFiles={backOnSelectFiles} />;
        }
    }, [handleOnSelectFiles, isSelectingFiles, selectedFiles]);

    useEffect(() => {
        const fetchData = async () => {
            if (isLoad==false && currentUser != null && currentUser.userId != undefined) {
                try {
                    let CustomersResult = await dispatch(customerActions.GetByUserId(currentUser.userId));
                    customerActions.SetUploadData(currentUser.userId, CustomersResult[0].code, CustomersResult[0].name);
                    handleOnSelectCustomer(CustomersResult[0].code);
                    setCustomers(CustomersResult);
                    setIsLoad(true);
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchData();
    }, [dispatch]);

    return (
        <>
            <UTSLayout>
                <h1>Upload Files</h1>

                <Paper style={{ padding: "5px" }}>
                    <Card style={{ height: "100%" }}>
                        <CardContent style={{ height: "100%", padding: "0px" }}>
                            <Box height={"100%"} display={"flex"} flexDirection={"column"}>
                                <FormControl style={{ marginTop: "8px", width: "20rem" }}>
                                    <InputLabel id="demo-simple-select-label">HCO</InputLabel>
                                    <Select
                                        className={classes.selectWrraper}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Customer"
                                        color="primary"
                                        disabled={!isSelectingFiles}
                                        value={selectedCustomer || ''} 
                                        onChange={(e) => {
                                            customerActions.SetUploadData(currentUser.userId, e.target.value, e.currentTarget.innerText);
                                            handleOnSelectCustomer(e.target.value);
                                        }}
                                    >
                                        {data.map((customer, index) => (
                                            <MenuItem key={index} style={{ color: "black" }} value={customer.code}>
                                                {customer.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {renderTabs}
                            </Box>
                        </CardContent>
                    </Card>
                </Paper>
            </UTSLayout>
        </>
    );
}
