import { userConstants } from "../../constants/user.constants";
import {handleResponse,handleError} from "../../services/handleError";
import { userService } from "../../services/user.service";
import { alertActions } from "./alert.actions";
import { history } from "../helpers/history";
// import jwt from 'jwt-decode';
import config from "../../config";
import axios from "axios";

export const userActions = {
  login,
  logout,
  register,
  getAll,
  delete: _delete,
  setUser,
  getAllRoles,
  getUserProfile,
  getAllUsers,
  getDeletedUsers,
  getReferringPhysicianUsers,
  updateUser,
  deleteUser,
  changePassword,
  RedirectToPassword,
  forgotPassword,
  resetPassword,
  getLoginUser,
  // ValidateToken
};
function setUser(user) {
   sessionStorage.setItem("user",JSON.stringify(user));
  return { type: userConstants.LOGIN_SUCCESS, user };
}

function login(user) {
  return async (dispatch) => {
    try {
      // dispatch(request({ email: user.email }));
      const resp = await userService.login(user);
      if (resp.success) {
         sessionStorage.setItem('token', resp.token);
        setUser(resp.user);
       // success(resp.loginUser);
        return true;
      }
     // dispatch(alertActions.error("login user failed"));
      //  dispatch(failure(error));
      //  dispatch(alertActions.error(error));
      return false;
    } catch (error) {
      //alert(error);
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    }


  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
   sessionStorage.removeItem('token')
   sessionStorage.removeItem("user");
  history.push('/login');
  return { type: userConstants.LOGOUT };
}
function RedirectToPassword() {
  history.push('/ChangePassword');
}
function updateUser(user){
  return async (dispatch) => {
    dispatch(request(user));
    try {
      const result = await userService.UpdateUser(user);
      if (result) {
        if (result.succeeded) {
          dispatch(alertActions.success("Update User successful"));
        } else {
          dispatch(alertActions.error("Update user failed"));
          dispatch(failure("Update user failed"));
        }
        return result;  
      } else {
        dispatch(failure("Update user failed"));
        dispatch(alertActions.error("Update user failed"));
        return null;  
      }
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      return null;  
    }
  }
  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}
// export function ValidateToken(token) {
// return async (dispatch) => {
//   try {
//     const requestOptions = {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json"
//       }
//     };
//     const response = await fetch(`${config.baseUrl}User/ValidateToken?token=${token}`, requestOptions);
//     const result = await handleResponse(response);
//     return result;
//   }
// }
// }

function deleteUser(userId) {
  return async (dispatch) => {
    dispatch(request(userId));

    try {
      const result = await userService.DeleteUser(userId);
      if (result) {
        if (result.succeeded) {
          dispatch(alertActions.success("delete user successful"));
        } else {
          dispatch(alertActions.error("delete user failed"));
          dispatch(failure("delete user failed"));
        }
        return result;  
      } else {
        dispatch(failure("delete user failed"));
        dispatch(alertActions.error("delete user failed"));
        return null;  
      }
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      return null;  
    }
  };


  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}
function register(user) {
  return async (dispatch) => {
    dispatch(request(user));

    try {
      const result = await userService.register(user);
      if (result) {
        if (result.succeeded) {
          dispatch(alertActions.success("Registration successful"));
        } else {
          dispatch(alertActions.error("Registration failed"));
          dispatch(failure("Registration failed"));
        }
        return result;  
      } else {
        dispatch(failure("Registration failed"));
        dispatch(alertActions.error("Registration failed"));
        return null;  
      }
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      return null;  
    }
  };


  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function getAll() {
  return (dispatch) => {
    dispatch(request());

    userService.getAll().then(
      (users) => dispatch(success(users)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GETALL_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
}
function getAllRoles() {
  return async (dispatch) => {
    dispatch(request());

    var roles = await userService.GetAllRoles();
    return roles;
  };
  function request() {
    return { type: userConstants.GETALLROLES_REQUEST };
  }
}
function getAllUsers() {
  return async (dispatch) => {
    dispatch(request());

    var users = await userService.GetAllUsers();
    return users;
  };
  function request() {
    return { type: userConstants.GetAllUsers_REQUEST };
  }
}
function getDeletedUsers(){
  return async (dispatch) => {
    dispatch(request());

    var users = await userService.GetDeletedUsers();
    return users;
  };
  function request() {
    return { type: userConstants.GetDeletedUsers_REQUEST };
  }
}
function getReferringPhysicianUsers(branchId){
  return async (dispatch) => {
    dispatch(request());

    var users = await userService.GetReferringPhysicianUsers(branchId);
    return users;
  };
  function request() {
    return { type: userConstants.GetReferringPhysicianUsers_REQUEST };
  }
}
function getUserProfile(userId) {
  return async (dispatch) => {
    try{
    var user = await userService.GetUserProfile(userId);
    dispatch(setUser(user));
    return user;
    }catch(err){
      return null;
    }
  };

}
// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return (dispatch) => {
    dispatch(request(id));

    userService.delete(id).then(
      () => {
        dispatch(success(id));
      },
      (error) => {
        dispatch(failure(id, error));
      }
    );
  };

  function request(id) {
    return { type: userConstants.DELETE_REQUEST, id };
  }
  function success(id) {
    return { type: userConstants.DELETE_SUCCESS, id };
  }
  function failure(id, error) {
    return { type: userConstants.DELETE_FAILURE, id, error };
  }
}
function changePassword(user) {
  return async (dispatch) => {
    dispatch(request(user));

    try {
      const result = await userService.ChangePassword(user);
      if (result) {
        if (result.succeeded) {
          dispatch(alertActions.success("Change Password successful"));
        } else {
          dispatch(alertActions.error("Change Password  failed"));
          dispatch(failure("Change Password  failed"));
        }
        return result;  
      } else {
        dispatch(failure("Change Password  failed"));
        dispatch(alertActions.error("Change Password  failed"));
        return null;  
      }
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      return null;  
    }
}
function request(user) {
  return { type: userConstants.REGISTER_REQUEST, user };
}
function failure(error) {
  return { type: userConstants.REGISTER_FAILURE, error };
}
}
function resetPassword(user){
  return async (dispatch) => {
    dispatch(request(user));

    try {
      const result = await userService.ResetPassword(user);
      if (result) {
        if (result.succeeded) {
          dispatch(alertActions.success("Reset Password successful"));
        } else {
          dispatch(alertActions.error("Reset Password  failed"));
          dispatch(failure("Reset Password  failed"));
        }
        return result;  
      } else {
        dispatch(failure("Reset Password  failed"));
        dispatch(alertActions.error("Reset Password  failed"));
        return null;  
      }
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      return null;  
    }
}
function request(user) {
  return { type: userConstants.REGISTER_REQUEST, user };
}
function failure(error) {
  return { type: userConstants.REGISTER_FAILURE, error };
}
}
function forgotPassword(email) {
  return async (dispatch) => {
    dispatch(request(email));

    try {
      const result = await userService.ForgotPassword(email);
      if (result) {
        if (result.succeeded) {
          dispatch(alertActions.success("Forgot Password successful"));
        } else {
          dispatch(alertActions.error("Forgot Password  failed"));
          dispatch(failure("Forgot Password  failed"));
        }
        return result;  
      } else {
        dispatch(failure("Forgot Password  failed"));
        dispatch(alertActions.error("Forgot Password  failed"));
        return null;  
      }
    } catch (error) {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
      return null;  
    }
}
function request(user) {
  return { type: userConstants.REGISTER_REQUEST, user };
}
function failure(error) {
  return { type: userConstants.REGISTER_FAILURE, error };
}
}
function getLoginUser() {
  let user=  sessionStorage.getItem("user");
   return JSON.parse(user);
}