import AddCustomerPage from "./AddCustomerPage";

export const AddCustomerPageConfig = {
  routes: [
    {
      path: "/AddHCO",
      exact: true,
      component: AddCustomerPage,
      protected: true,
      roles:['Admin']
    }
  ]
};
