import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  my3: {
    margin: "1.3rem 0",
  },
  contactInfo: {
    padding: theme.spacing(3),
    maxWidth: "600px",
    margin: "auto",
  },
  contactItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  selectWrapper: {
    marginTop: theme.spacing(2),
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
}));

const UploadReportDialog = ({ open, onClose, selectedStudy, uploadFile, UploadReport }) => {
  const handleConfirmUploadReportDialog = async () => {
    await UploadReport();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Upload Report</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to upload report ({uploadFile?.name?.replace(".docx", "")}) 
          that doesn't match Patient Id ({selectedStudy?.patientId})?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirmUploadReportDialog} color="secondary">
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadReportDialog;
