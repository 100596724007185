import React, { useState, useEffect } from "react";
import UTSLayout from "../../../components/UTSLayout/UTSLayout";
import UTSBreadcrumbs from "../../../components/UTSBreadcrumbs/UTSBreadcrumbs";
import {alertActions} from "../../../redux/actions/alert.actions";
import {
  Typography,
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  makeStyles
} from "@material-ui/core";
import {userActions} from"../../../redux/actions/user";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSelector, useDispatch } from "react-redux";
import MUIDataTable from "mui-datatables";
import { customerActions } from "../../../redux/actions/customer";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { templatesActions } from "../../../redux/actions/template";

const useStyles = makeStyles((theme) => ({
  my3: {
    margin: "1.3rem 0",
  },
  mb0: {
    marginBottom: 0,
  },
  mRight: {
    marginRight: ".85rem",
  },
  p1: {
    padding: ".85rem",
  },
}));

const options = {
  filterType: "checkbox",
  selectableRows: "none",
  rowsPerPage: 10,
  rowsPerPageOptions: [5, 10, 15, 20],
};

const AllTemplatesPage = (props) => {
  const { history } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isload, setIsload] = useState(false);
  const [data, setTemplates] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const currentUser = userActions.getLoginUser();

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      let uploadResult = await dispatch(templatesActions.uploadTemplate(file));
      if (uploadResult) {
        dispatch(alertActions.success("The file uploaded successfully."));
        setIsload(false);
        setIsload(true);
      } else {
        dispatch(alertActions.error("The file upload failed."));
      }
    }
  };
   const handleDownload=async(rowIndex)=>{
    const selectedRow = data[rowIndex];
    let uploadResult = await dispatch(templatesActions.DownloadTemplate(selectedRow.name)); 
    setIsload(false);
    setIsload(true);
   };
  const handleEdit = (rowIndex) => {
    const selectedRow = data[rowIndex];
    setSelectedTemplate(selectedRow); 
    document.getElementById("upload-edit-button").click(); 
  };

  const handleEditUpload = async (event) => {
    const file = event.target.files[0];
    if (file && selectedTemplate) {
      let uploadResult = await dispatch(templatesActions.EditTemplate(file,selectedTemplate)); // Adjust the action for editing
      if (uploadResult) {
       dispatch(alertActions.success("The file has been replaced successfully."));
        setIsload(false);
        setIsload(true);
      } else {
        dispatch(alertActions.error("Failed to replace the file."));
      }
    }
  };

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              {currentUser.roles && currentUser.roles.includes('Admin') && (
                <>
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => handleEdit(tableMeta.rowIndex)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    size="small"
                    onClick={() => handleDelete(tableMeta.rowIndex)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </>
              )}
              <IconButton 
                 color="primary" 
                 size="small"
                 onClick={() => handleDownload(tableMeta.rowIndex)}
                >
                <CloudDownloadIcon style={{ color: "#4CAF50" }} />
              </IconButton>
            </>
          );
        },
      },
    },
  ];

  const fetchData = async () => {
    try {
      let TemplatesResult = await dispatch(templatesActions.GetAllTemplates());
      setTemplates(TemplatesResult);
    } catch (error) {
      console.error("Error fetching template data:", error);
    }
  };

  const handleDelete = (rowIndex) => {
    const selectedRow = data[rowIndex];
    setSelectedTemplate(selectedRow);
    setOpenDialog(true);
  };

  const confirmDelete = async () => {
    await dispatch(templatesActions.DeleteTemplate(selectedTemplate.id));
    setOpenDialog(false);
    setIsload(false);
    setIsload(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedTemplate(null);
  };

  useEffect(() => {
    fetchData();
  }, [isload, dispatch]);

  return (
    <UTSLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight}>
          <Typography variant="h5" component="h1">
            Templates
          </Typography>
        </Grid>
        {currentUser.roles && currentUser.roles.includes('Admin') && (
        
          <Grid item>
            {/* Add upload button for adding new templates */}
            <input
              accept="*"
              style={{ display: "none" }}
              id="upload-button"
              type="file"
              onChange={handleUpload}
            />
            <label htmlFor="upload-button">
              <IconButton color="primary" component="span">
                <CloudUploadIcon />
              </IconButton>
            </label>
          </Grid>
        )}
      </Grid>
       
      <MUIDataTable
        title={"Templates List"}
        data={data}
        columns={columns}
        options={options}
      />
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this template?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* Hidden file input for editing */}
      <input
        accept="*"
        style={{ display: "none" }}
        id="upload-edit-button"
        type="file"
        onChange={handleEditUpload}
      />
    </UTSLayout>
  );
};

export default AllTemplatesPage;
