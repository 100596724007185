import AllStudiesPage from "./AllStudiesPage";

export const AllStudiesPageConfig = {
  routes: [
    {
      path: "/studies",
      exact: true,
      component: AllStudiesPage,
      protected: true,
      // roles:["Admin","Consultant","Specialist","Client"],
    },
    {
      path: "/",
      exact: true,
      component: AllStudiesPage,
      protected: true,
      // roles:["Admin","Consultant","Specialist","Client"],
    }
  ]
};
