import ChangePasswordPage from "./ChangePasswordPage";

export const ChangePasswordPageConfig = {
  routes: [
    {
      path: "/ChangePassword",
      exact: true,
      component: ChangePasswordPage,
      protected: true,
      roles:['Admin',
        'Consultant',
        'Specialist',
        'Client']

    }
  ]
};
