import "./ForgotPasswordPage.css";
import React, { useState } from 'react';
import  { useEffect } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { userActions } from "../../../redux/actions/user";
import MuiCard from "../../../components/extra/card/MuiCard";

const ForgotPasswordPage = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const propsData = location.state;
    const [error, setError] = React.useState('');

    let [user, setUser] = useState({
         email: ''
    });


    let [isDarkMode, setIsDarkMode] = useState(false);

    function getUserData(e) {
        let copyUser = { ...user };
        copyUser[e.target.name] = e.target.value;
        setUser(copyUser);
    }

    const onLogin = async (e) => {
        e.preventDefault();
        setError('');
        let result = await dispatch(userActions.forgotPassword(user.email));
        if (result) {
            history.push('/login');
        }else{
            setError('Error While send email');
        }
    }

    return (
        <div className={`login ${isDarkMode ? 'darkMode' : ''}`}>
            <div className="loginContainer">
                <div className="mainDiv">
                    <MuiCard className={`cardStyle ${isDarkMode ? 'darkMode' : ''}`}>
                        <form onSubmit={onLogin}>
                            <img
                                style={{ width: '10rem' }}
                                src='https://unitedtelescans.com/wp-content/uploads/2024/07/LOGO-01-1-1.png'
                                alt="logo"
                                id="loginLogo"
                            />

                            <h2 className={`formTitle ${isDarkMode ? 'darkMode' : ''}`}>
                                Forgot Password
                            </h2>
                            <hr className={`horizontalLine ${isDarkMode ? 'darkMode' : ''}`} />

                            <div className="inputDiv">
                                <label className={`inputLabel ${isDarkMode ? 'darkMode' : ''}`}>Email</label>
                                <input
                                    type="text"
                                    onChange={getUserData}
                                    name="email"
                                    autoComplete="off"
                                />
                            </div>


                            <div className='container d-flex justify-content-center w-100'>
                                <div className='errorMessageDiv'>
   
                                            {error}
                                </div>
                            </div>

                            <div className="buttonWrapper">
                                <button
                                    style={{
                                        background: 'linear-gradient(to right, #36648B 0%, #36648B 79%)',
                                        textTransform: 'uppercase',
                                        letterSpacing: '0.2rem',
                                        width: '65%',
                                        height: '3rem',
                                        border: 'none',
                                        color: 'white',
                                        borderRadius: '2rem',
                                        cursor: 'pointer'
                                    }}
                                    type="submit"
                                    className="submitButton pure-button pure-button-primary"
                                >
                                    <span>Send</span>
                                </button>
                            </div>
                        </form>
                    </MuiCard>
                </div>
            </div>
        </div>
    );
};

export default ForgotPasswordPage;
