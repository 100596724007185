import UploadPage from "./UploadPage";

export const UploadPageConfig = {
  routes: [
    {
      path: "/upload",
      exact: true,
      component: UploadPage,
      protected: true
    }
  ]
};
