import React, { useRef } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { IconButton } from "@material-ui/core";
import FolderIcon from '@material-ui/icons/Folder'; // Import the Folder icon

import { FileUploader } from 'react-drag-drop-files';
import { useStyles } from './styles';
import { alertActions } from "../../redux/actions/alert.actions";
import { useDispatch } from 'react-redux';

export default function SelectingFilesTab(props) {
  const dispatch = useDispatch();
  const { onSelectFiles } = props;
  const classes = useStyles();
  const allowedExtensions = ['dcm', 'dcn', 'dic'];
  const fileInputRef = useRef(null);

  const handleChange = (files) => {
    const selectedFiles = [];
    const fileArray = Array.from(files);
    fileArray.forEach((file, index) => {
      const fileExtension = file.name.split('.').pop().toLowerCase();

      selectedFiles.push({
        id: `${file.name + Math.random() * 100}`,
        file: file,
        status: index === 0 ? 'InProgress' : 'InQueue',
      });
    });
    if (selectedFiles.length > 0) {
      onSelectFiles(selectedFiles);
    }
  };

  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files) {
      handleChange(files);
    }
  };

  return (
    <>
      <Box className={classes.selectFilesTab}>
        <Typography
          variant="h4"
          gutterBottom
          className={classes.titleText}
          mt={2}
        >
          Select Patient Case Folder 
        </Typography>
        <div className={classes.folderUploader} onClick={triggerFileInput}>
          <IconButton
            onClick={triggerFileInput}
            
            className={classes.iconButton} // Apply custom styles to IconButton
            color="primary"
          >
            <FolderIcon /> {/* Folder icon */}
          </IconButton>
          <span style={{fontSize: '12px',color: '#666'}}>Upload dcm, dcn or dic folders</span>
        </div>

        <input
          type="file"
          directory=""
          webkitdirectory=""
          onChange={handleFileChange}
          className={classes.fileInput} // Hidden file input
          multiple
          ref={fileInputRef}
          style={{ display: 'none' }} // Hide the file input element
        />
      </Box>

      <Box className={classes.selectFilesTab}>
        <Typography
          variant="h4"
          gutterBottom
          className={classes.titleText}
          mt={2}
        >
          Select Patient Case Files
        </Typography>
        <FileUploader
          classes={classes.fileUploader}
          label="Drag and drop dcm, dcn or dic files"
          multiple={true}
          handleChange={handleChange}
          name="file"
        />
      </Box>
    </>
  );
}
