import React, { useState } from "react";
import "./App.css";
import UTSRoutes from "./components/UTSRoutes/UTSRoutes";
import { ThemeProvider } from "@material-ui/core/styles";
import getTheme from "./UTS-configs/themeConfig";
import ThemeContext from "./context/ThemeContext";
import {store, history} from './redux/store';
import {Provider} from 'react-redux';
const App = () => {
  const curThemeName =  sessionStorage.getItem("appTheme") || "light";

  const [themeType, setThemeType] = useState(curThemeName);

  const setThemeName = themeName => {
     sessionStorage.setItem("appTheme", themeName);
    setThemeType(themeName);
  };

  const theme = getTheme({
    paletteType: themeType
  });

  return (
    <Provider store={store}>
    <ThemeContext.Provider store={store}  value={{ setThemeName, curThemeName }}>
      <ThemeProvider theme={theme}>
        <div className="App">
          <UTSRoutes />
        </div>
      </ThemeProvider>
    </ThemeContext.Provider>
    </Provider>
  );
};

export default App;
