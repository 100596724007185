export const  DOWNLOAD_REQUEST="DOWNLOAD_REQUEST";
export const  DOWNLOAD_SUCCESS="DOWNLOAD_SUCCESS";
export const  DOWNLOAD_FAILURE="DOWNLOAD_FAILURE";
export const USER_LIST_FAILED="USER_LIST_FAILED";
export const STUDIES_REQUEST = 'STUDIES_REQUEST';
export const STUDIES_SUCCESS = 'STUDIES_SUCCESS';
export const STUDIES_FAILURE = 'STUDIES_FAILURE';
// Action Types
export const GET_VIEWER_ID_REQUEST = "GET_VIEWER_ID_REQUEST";
export const GET_VIEWER_ID_SUCCESS = "GET_VIEWER_ID_SUCCESS";
export const GET_VIEWER_ID_FAILURE = "GET_VIEWER_ID_FAILURE";
