import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import {
  Typography,
  Paper,
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Card,
  CardContent,
  Box,
  IconButton,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import AddReferringPhysicianDialog from "./AddReferringPhysicianDialog";
import { userActions } from "../../../../redux/actions/user";
import { studiesActions } from "../../../../redux/actions/study";
import Autocomplete from "@material-ui/lab/Autocomplete"; // Import Autocomplete

const useStyles = makeStyles((theme) => ({
  my3: {
    margin: "1.3rem 0",
  },
  contactInfo: {
    padding: theme.spacing(3),
    maxWidth: "600px",
    margin: "auto",
  },
  contactItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  selectWrapper: {
    marginTop: theme.spacing(2),
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  }
}));

const ReferringPhysicianDialog = ({ open, onClose, selectedStudy }) => {
  const classes = useStyles();
  const [selectedPhysician, setSelectedPhysician] = useState(null);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();
  const currentUser = userActions.getLoginUser();

  const handleOpenAddDialog = () => {
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
    setIsRefresh(!isRefresh);
  };

  const onAssignReferringPhysician = async () => {
    if (selectedPhysician) {
      await dispatch(studiesActions.UpdateReferringPhysician(
        selectedStudy.studyIncId,
        currentUser.userId,
        selectedPhysician.userId,
        selectedPhysician.email
      ));
    }
    onClose();
  };

  const fetchData = async () => {
    setIsLoad(true);
    try {
      const usersData = await dispatch(userActions.getReferringPhysicianUsers(currentUser.customers[0]));
      setSelectedPhysician(usersData.find(x => x.email === selectedStudy.referringPhysician));
      setUsers(usersData);
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setIsLoad(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [isRefresh, dispatch]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Referring Physician ( {selectedStudy.patientId} )</DialogTitle>
      <DialogContent>
        {isLoad ? (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        ) : (
          <Paper style={{ padding: "5px", width: "20rem" }}>
            <Card style={{ height: "100%" }}>
              <CardContent style={{ height: "100%", padding: "0px" }}>
                <Box height={"100%"} display={"flex"} flexDirection={"row"}>
                  <FormControl className={classes.selectWrapper} fullWidth>
                    <Autocomplete
                      id="referring-physician-autocomplete"
                      options={users}
                      getOptionLabel={(option) => option.email}
                      value={selectedPhysician}
                      onChange={(e, newValue) => setSelectedPhysician(newValue)}
                      renderInput={(params) => (
                        <TextField {...params} label="Referring Physician" variant="outlined" />
                      )}
                    />
                  </FormControl>
                  <div style={{ textAlign: "right" }}>
                    <IconButton style={{ marginTop: '1.2rem' }} color="primary" title="Add new referring physician" onClick={handleOpenAddDialog}>
                      <AddIcon />
                    </IconButton>
                  </div>
                </Box>
              </CardContent>
            </Card>
          </Paper>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onAssignReferringPhysician} color="secondary">
          Assign
        </Button>
      </DialogActions>

      {openAddDialog && (
        <AddReferringPhysicianDialog
          open={openAddDialog}
          onClose={handleCloseAddDialog}
        />
      )}
    </Dialog>
  );
};

export default ReferringPhysicianDialog;
