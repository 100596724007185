import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import config from '../../config';

import {customerActions} from "../../redux/actions/customer"
export function UseUploadPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(0);
  const [isSelectingFiles, setIsSelectingFiles] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([
  ]);


  const handleOnSelectCustomer = useCallback((newCustomer) => {
    setSelectedCustomer(newCustomer);
  }, []);

  const uploadDicomDocument = useCallback(async (formData) => {
    const configHeader = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: 'Bearer ' +  sessionStorage.getItem('token'),
      },
    };
   return axios
     .post(`${config.baseUrl}Upload/UploadDicomFile`, formData, configHeader)
     .then((response) => {
       return response.data;
     },(err)=>{
      return err.data
     });
  }, []);

  const uploadFiles = useCallback(
    async (files) => {
      for (const file of files) {
        const result = await UploadFile(file);
        setSelectedFiles((prev) => {
          const selectedFileIndex = prev.findIndex((_file) => _file.id === file.id);
               prev[selectedFileIndex].status = result ? 'Success' : 'Fail';
          if (selectedFileIndex < files.length - 1) {
            prev[selectedFileIndex + 1].status = 'InProgress';
          
          }
          return [...prev];
        });
      }


    },
    [uploadDicomDocument]
  );
  const  UploadFile=async(file) =>{
    var uploadData=customerActions.getUploadData();
    const formData = new FormData();
    formData.append('file', file.file);
    formData.append('fileName', file.file.name);
    formData.append('userId', uploadData.userId);
    formData.append('customerId', uploadData.customerId);
    formData.append('customerName', uploadData.customerName);
    const result = await uploadDicomDocument(formData);
    return result;
  }
  const handleOnSelectFiles = useCallback(
    (files) => {

      setSelectedFiles(files);
      setIsSelectingFiles(false);
      uploadFiles(files);
    },
    [uploadFiles]
  );
  const backOnSelectFiles = useCallback(
    () => {
      setIsSelectingFiles(true);
    },
    []
  );
  return {
    isLoading,
    selectedCustomer,
    selectedFiles,
    isSelectingFiles,
    handleOnSelectCustomer,
    handleOnSelectFiles,
    backOnSelectFiles,
    UploadFile,
  };
}

