import UsersPage from "./DeletedUsersPage";

export const DeletedUsersPageConfig = {
  routes: [
    {
      path: "/DeletedUsers",
      exact: true,
      component: UsersPage,
      protected: true,
      roles:['Admin']
    }
  ]
};
