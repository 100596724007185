import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { useDispatch } from 'react-redux';
import { studiesActions } from "../../../../redux/actions/study";
import { userActions } from "../../../../redux/actions/user";
import { green } from '@material-ui/core/colors';
const ApproveDialog = ({ open, onClose, selectedStudy,setProgressWord,setLoading,setIsRefresh,isRefresh}) => {
    const dispatch = useDispatch();
    const currentUser = userActions.getLoginUser();
    const handleConfirmApproveDialog = async () => {
        setProgressWord("Preparing PDF");
        const prepareTimer = setTimeout(() => {
          setProgressWord("Completing Report");
        }, 7000);
        setLoading((prev) => ({ ...prev, approve: true }));
        try {
          let approve = await dispatch(studiesActions.ApproveStudy(selectedStudy.customerName, selectedStudy.studyInstanceUID, selectedStudy.studyIncId, currentUser.userId, selectedStudy.patientCode));
          setIsRefresh(!isRefresh);
        } catch (error) {
          console.error("Approval error:", error);
        } finally {
          clearTimeout(prepareTimer);
          setProgressWord("");
          setLoading((prev) => ({ ...prev, approve: false }));
          onClose();
        }
      }

  return (
    <Dialog
    open={open}
    onClose={onClose}
  >
    <DialogTitle>Confirm Report Completed</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Are you sure you want to Completed this study?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Cancel
      </Button>
      <Button onClick={handleConfirmApproveDialog} style={{ color: green[500] }}>
        Report Completed
      </Button>
    </DialogActions>
  </Dialog>
  );
};

export default ApproveDialog;
