import React, {useState} from 'react'
import Card from '@material-ui/core/Card';
import "./MuiCard.css";


const MuiCard = ({ children, className, ...props }) => {
    return (
        <Card className={`MyMuiCard ${className}`} {...props}>
            {children}
        </Card>
    );
};

export default MuiCard;
