import RegisterPage from "./RegisterPage";

export const RegisterPageConfig = {
  routes: [
    {
      path: "/register",
      exact: true,
      component: RegisterPage,
      protected: true,
      roles:['Admin']
    }
  ]
};
