import {USER_LIST_FAILED} from '../actionTypes/actionTypes'
  const INITIAL_STATE = {
    userListFailed:false
  };
  
  export function userListReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
      case USER_LIST_FAILED:
        return { ...state, userListFailed: action.payload };
      default:
        return state;
    }
  }
  