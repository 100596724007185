import React, { useState, useRef, useEffect } from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { userActions } from "../../../redux/actions/user";
import {customerActions} from "../../../redux/actions/customer";
import { useDispatch } from 'react-redux';
import AddAutoComplete from "../../../components/extra/Controls/AddAutoComplete";
import { useSelector } from 'react-redux';
import {
  Button,
  Divider,
  ExpansionPanelActions,
} from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import UTSLayout from "../../../components/UTSLayout/UTSLayout";
import UTSBreadcrumbs from "../../../components/UTSBreadcrumbs/UTSBreadcrumbs";
import { Typography, Grid, makeStyles, TextField } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  my3: {
    margin: "1.3rem 0"
  },
  mb3: {
    margin: "1.3rem 0"
  },
  mb0: {
    marginBottom: 0
  },
  mRight: {
    marginRight: ".85rem"
  },
  p1: {
    padding: ".85rem"
  }
}));
const roles = [
  { id: "Admin", title: "Admin" },
  { id: "Doctor", title: "Doctor" },
  // { title: "react" },
  // { title: "material ui" }
];
const RegisterPage = props => {
  const [expanded, setExpanded] = React.useState(true);
  const [email, setEmail] = useState('');
  const [errorEmail, setEmailError] = useState('');
  const [errorFirstName, setFirstNameError] = useState('');
  const [errorLastName, setLastNameError] = useState('');
  const [errorConfirmationPassword, setConfirmationPassword] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [errorAutoComplete, setErrorAutoComplete] = useState('');
  const [errorAutoCompleteCustomers, setErrorAutoCompleteCustomers] = useState('');
  const [errors, setErrors] = useState([]);
  const [roles, setRoles] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [isload, setIsload] = React.useState(false);
  const dispatch = useDispatch();
  const historyRef = useHistory();
  let [user, setUser] = useState(props.location.state?.user ?? {});
  const currentUser =userActions.getLoginUser();
  function getUserData(e) {
    let copyUser = { ...user };
    copyUser[e.target.name] = e.target.value;
    setUser(copyUser);
  }
  function getRoleData(e) {
    let copyUser = { ...user };
    copyUser.roles =e.map(x=>x.id);
    setUser(copyUser);
  }
  function getCustomerData(e) {
    if (user.roles !=null && user.roles.includes("Client")) {
      let copyUser = { ...user };
      if (e.length > 0) {
        copyUser.customers = [e[e.length - 1].id]; // Get the latest item
    } else {
        copyUser.customers = []; // Handle case where e is empty
    }
      setUser(copyUser);
    } else {
      // For non-clients, update the customers with the provided data
      let copyUser = { ...user };
      copyUser.customers = e.map(x => x.id);
      setUser(copyUser);
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      if (!isload) {
        try {
          let rolesResult = await dispatch(userActions.getAllRoles());
           let CustomersResult= await dispatch(customerActions.getAllCustomers());
           setCustomers(CustomersResult);
          setRoles(rolesResult);
          setIsload(true);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchData();
  }, [isload, dispatch]);
  const handleExpandedChange = () => {
    setExpanded(!expanded);
  };

  const { history } = props;
  const classes = useStyles();
  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors([]);
    let isValid = ValidateForm();
    if (isValid) {
      user.createdBy = currentUser.email;
      let result = null;
      if (user.userId == null || user.userId == "") {
        result = await dispatch(userActions.register(user));
      } else {
        result = await dispatch(userActions.updateUser(user));
      }
      if (result != null) {
        if (result.succeeded) {
          historyRef.push("/Users")
        } else {
          setErrors(result.errors);
        }
      }
    }

    function ValidateForm() {
      let isValid = true;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(user.email)) {
        isValid = false;
        setEmailError('Please enter a valid email address.');
      } else {
        setEmailError('');
      }
      if ((user.userId == "" || user.userId == null) && (user.password !== user.confirmPassword)) {
        isValid = false;
        setConfirmationPassword('Passwords do not match.');
      } else {
        setConfirmationPassword('');
      }
      if (user.firstName == "" || user.firstName == null) {
        isValid = false;
        setFirstNameError('FirstName is required.');
      } else {
        setFirstNameError('');
      }
      if (user.lastName == "" || user.lastName == null) {
        isValid = false;
        setLastNameError('LastName is required.');
      } else {
        setLastNameError('');
      }
      if ((user.userId == "" || user.userId == null) && (user.password == "" || user.password == null)) {
        isValid = false;
        setErrorPassword('Password is required.');
      } else {
        setErrorPassword('');
      }
      if ( user.roles == null || user.roles.length==0) {
        isValid = false;
        setErrorAutoComplete('Roles is required.');
      } else {
        setErrorAutoComplete('');
      }
      if ( user.customers == null || user.customers.length==0) {
        isValid = false;
        setErrorAutoCompleteCustomers('Customers is required.');
      } else {
        setErrorAutoCompleteCustomers('');
      }
      return isValid;
    }
  };
  return (
    <UTSLayout>
      <Typography className={classes.mb3} variant="h5" component="h1">
        {user.userId?'Edit User':'Add New User'}
      </Typography>
       
      <div className={classes.root} >
        <Grid container spacing={3}>

          <Grid item xs={12} md={8}>
            <div className={classes.root}>
              {errors.length > 0 ? (
                <ExpansionPanel expanded={true} className={classes.mb3}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className={classes.heading}>Errors</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid container spacing={2}>
                      {errors.map((error, index) => (
                        <Grid item xs={12} md={12} key={index}>
                          <label style={{ color: 'red' }}>{error.description}</label>
                        </Grid>
                      ))}
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ) : null}
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3}>

          <Grid item xs={12} md={8}>
            <div className={classes.root}>

              <ExpansionPanel expanded={expanded} className={classes.mb3}>
                <ExpansionPanelSummary
                  onClick={handleExpandedChange}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>User{':  '+(user?.firstName==null?'':user?.firstName)}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid container spacing={0}>
                    <Grid item xs={12} md={12}>
                      <TextField
                        name="firstName"
                        label="First Name"
                        className={classes.mb3}
                        placeholder="Enter First Name here"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true
                        }}
                        value={user.firstName}
                        onChange={getUserData}
                        error={!!errorFirstName}
                        helperText={errorFirstName}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        name="lastName"
                        label="Last Name"
                        className={classes.mb3}
                        placeholder="Enter Last Name here"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true
                        }}
                        value={user.lastName}
                        onChange={getUserData}
                        error={!!errorLastName}
                        helperText={errorLastName}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        name='email'
                        label="Email"
                        className={classes.mb3}
                        placeholder="Enter Email here"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete="false"
                        value={user.email}
                        onChange={getUserData}
                        error={!!errorEmail}
                        helperText={errorEmail}
                      />
                    </Grid>
                    {(user.userId == "" || user.userId == null) ? (
                      <Grid item xs={12} md={12}>
                        <TextField
                          name="password"
                          label="Password"
                          type="password"
                          className={classes.mb3}
                          placeholder="Enter Password here"
                          fullWidth
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          autoComplete="new-password"
                          value={user.password}
                          onChange={getUserData}
                          error={!!errorPassword}
                          helperText={errorPassword}
                        />
                      </Grid>
                    ) : null}
                    {(user.userId == "" || user.userId == null) ? (
                      <Grid item xs={12} md={12}>
                        <TextField
                          name="confirmPassword"
                          label="Confirm Password"
                          type="password"
                          className={classes.mb3}
                          placeholder="Confirm Password here"
                          fullWidth
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={user.confirmPassword}
                          onChange={getUserData}
                          error={!!errorConfirmationPassword}
                          helperText={errorConfirmationPassword}
                          autoComplete="new-password"
                        />
                      </Grid>
                    ) : null}
                    <Grid item xs={12}>
                      {(isload) ? (
                        <AddAutoComplete
                          getValue={getRoleData}
                          value={user.roles}
                          data={roles}
                          displayName={'title'}
                          label={'Roles'}
                          multiple={true}
                          error={!!errorAutoComplete}
                          helperText={errorAutoComplete} />
                      ) : null}
                    </Grid>
                    <Grid item xs={12} md={12} style={{marginTop:'1rem'}}>
                      {(isload) ? (
                        <AddAutoComplete
                          getValue={getCustomerData}
                          value={user.customers}
                          data={customers}
                          label={'HCO'}
                          multiple={true}
                          displayName={'name'}
                          error={!!errorAutoCompleteCustomers}
                          helperText={errorAutoCompleteCustomers} />
                      ) : null}
                    </Grid>
                  </Grid>
                </ExpansionPanelDetails>
                <Divider />
                <ExpansionPanelActions>
                  <Grid container justify="space-between">
                    <Button style={{ visibility: 'none' }} size="small">
                    </Button>
                    <Button variant="contained" onClick={handleSubmit} color="primary" size="small">
                      Save
                    </Button>
                  </Grid>
                </ExpansionPanelActions>
              </ExpansionPanel>

            </div>
          </Grid>
        </Grid>
      </div>
    </UTSLayout>
  );
};

export default RegisterPage;
