import ContactUsPage from "./ContactUsPage";

export const ContactUsConfig = {
  routes: [
    {
      path: "/Support",
      exact: true,
      component: ContactUsPage,
      protected: true,
    }
  ]
};
