import React, { useMemo, useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';
import { useTheme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import LinearProgress from '@material-ui/core/LinearProgress';
import { UseUploadPage } from '../../../src/redux/actions/useUploadPage';

export default function FileRow(props) {
  const { file: initialFile } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { UploadFile } = UseUploadPage();  // Access the hook and its functions

  // Manage file state so it can trigger re-render on changes
  const [file, setFile] = useState(initialFile);

  const fileStatusColor = useMemo(() => {
    switch (file.status) {
      case 'InQueue':
        return theme.palette.info.main;
      case 'InProgress':
        return theme.palette.primary.main;
      case 'Fail':
        return theme.palette.error.main;
      case 'Success':
        return theme.palette.success.main;
      default:
        return theme.palette.grey[500];
    }
  }, [file.status, theme.palette]);

  const progressBarStyle = {
    backgroundColor: theme.palette.grey[300],
    '& .MuiLinearProgress-bar': {
      backgroundColor: fileStatusColor,
    },
  };

  const retryUploadFile = async () => {
    setFile((prevFile) => ({ ...prevFile, status: 'InProgress' }));

    const result = await UploadFile(file);

    if (result) {
      setFile((prevFile) => ({ ...prevFile, status: 'Success' }));
    } else {
      setFile((prevFile) => ({ ...prevFile, status: 'Fail' }));
    }
  };

  return (
    <Box className={classes.fileRow}>
      <Box className={classes.fileRowContent}>
        <Box className={classes.rowTitleWrraper}>
          <Box>
            <Typography variant="body2" gutterBottom style={{ color: 'white' }}>
              FileName: {file.file.name}
            </Typography>
            <Typography variant="caption" gutterBottom style={{ color: 'white' }}>
              Size: {file.file.size}
            </Typography>
          </Box>
          <Box>
            {file.status === 'Fail' && (
              <Chip
                label="Retry"
                style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}
                size="medium"
                onClick={retryUploadFile}  
              />
            )}
            <Chip
              label={file.status}
              style={{ backgroundColor: fileStatusColor, color: 'white' }}
              size="medium"
            />
          </Box>
        </Box>
        <Box sx={{ width: '100%' }} mt={0.5}>
          <LinearProgress
            style={progressBarStyle}
            variant={file.status === 'InProgress' ? 'indeterminate' : 'determinate'}
            value={file.status === 'Success' || file.status === 'Fail' ? 100 : 0}
          />
        </Box>
      </Box>
    </Box>
  );
}
