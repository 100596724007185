import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    makeStyles,
} from "@material-ui/core";
import { useDispatch } from 'react-redux';
import { studiesActions } from "../../../../redux/actions/study";
import { userActions } from "../../../../redux/actions/user";
import { green } from '@material-ui/core/colors';
const CompleteDialog = ({ open, onClose, selectedStudy, setProgressWord, setLoading, setIsRefresh, isRefresh }) => {
    const dispatch = useDispatch();
    const currentUser = userActions.getLoginUser();
    const handleConfirmCompleteDialog = async () => {
        setProgressWord("Forwarding for review");
        setLoading((prev) => ({ ...prev, complete: true }));
        try {
          let approve = await dispatch(studiesActions.CompleteStudy(selectedStudy.studyIncId, currentUser.userId));
          setIsRefresh(!isRefresh);
        } catch (error) {
          console.error("Completion error:", error);
        } finally {
          setProgressWord("");
          setLoading((prev) => ({ ...prev, complete: false }));
          onClose();
        }
      };
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>Alert</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please ensure that all findings and observations are thoroughly reviewed before forwarding this report for final review. Once forwarded, the report will be locked for further edits. Do you wish to proceed?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleConfirmCompleteDialog} color="secondary" >
                    Forward
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CompleteDialog;
