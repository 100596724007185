import React from "react";
import UTSLayout from "../../components/UTSLayout/UTSLayout";
import {
  Typography,
  Grid,
  Paper,
  makeStyles
} from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";

const useStyles = makeStyles((theme) => ({
  my3: {
    margin: "1.3rem 0",
  },
  contactInfo: {
    padding: theme.spacing(3),
    maxWidth: "600px",
    margin: "auto",
  },
  contactItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const ContactUsPage = () => {
  const classes = useStyles();

  return (
    <UTSLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item>
          <Typography variant="h5" component="h1">
            Support
          </Typography>
        </Grid>
      </Grid>

      <Paper elevation={3} className={classes.contactInfo}>
        <Typography variant="h6" gutterBottom>
          You can contact us by:
        </Typography>

        {/* Email Display */}
        <div className={classes.contactItem}>
          <EmailIcon className={classes.icon} />
          <Typography variant="body1">Email: Support@unitedtelescans.com</Typography>
        </div>

        {/* Phone Display */}
        <div className={classes.contactItem}>
          <PhoneIcon className={classes.icon} />
          <Typography variant="body1">Phone: +13235031973</Typography>
        </div>
      </Paper>
    </UTSLayout>
  );
};

export default ContactUsPage;
