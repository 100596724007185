import React, { useState, useEffect } from "react";
import UTSLayout from "../../../components/UTSLayout/UTSLayout";
import {
  Typography, 
  Grid, 
  Button, 
  makeStyles, 
  IconButton, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle, 
  FormControlLabel, 
  Checkbox, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem
} from "@material-ui/core";

// import { Typography, Grid, Button, makeStyles, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Checkbox } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import GroupIcon from '@material-ui/icons/Group';
import MUIDataTable from "mui-datatables";
import { customerActions } from "../../../redux/actions/customer";
import { userActions } from "../../../redux/actions/user";
import { useDispatch } from 'react-redux';
import { alertActions } from "../../../redux/actions/alert.actions";
const useStyles = makeStyles(theme => ({
  my3: {
    margin: "1.3rem 0"
  },
  mb0: {
    marginBottom: 0
  },
  mRight: {
    marginRight: ".85rem"
  },
  p1: {
    padding: ".85rem"
  },
  checkboxLabel: {
    display: 'block',
  }
}));

const options = {
  filterType: "checkbox",
  selectableRows: "none",
  rowsPerPage: 10,
  rowsPerPageOptions: [5, 10, 15, 20],
};

const AllCustomersPage = props => {
  const { history } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isload, setIsload] = useState(false);
  const [data, setCustomers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [Users, setUsers] = useState([]);
  const [checkedUsers, setCheckedUsers] = useState({}); // To keep track of checked checkboxes
  const [selectAll, setSelectAll] = useState(false); // To track the Select All checkbox
  const [selectedRole, setSelectedRole] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(Users);
  const [roles, setRoles] = useState([]);
  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "code",
      label: "Code",
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <IconButton
                color="primary"
                size="small"
                onClick={() => handleEdit(tableMeta.rowIndex)}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                color="secondary"
                size="small"
                onClick={() => handleDelete(tableMeta.rowIndex)}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                color="default"
                size="small"
                onClick={() => handleUserAction(tableMeta.rowIndex)}
              >
                <GroupIcon  style={{ color: 'green' }} />
              </IconButton>
            </>
          );
        }
      }
    }
  ];

  const fetchData = async () => {
    try {
      let CustomersResult = await dispatch(customerActions.getAllCustomers());
      setCustomers(CustomersResult);
      setIsload(true);
    } catch (error) {
      console.error('Error fetching customer data:', error);
    }
  };

  const getAllUsers = async () => {
    try {
      let usersData = await dispatch(userActions.getAllUsers());
      setUsers(usersData);
      setFilteredUsers(usersData);
    } catch (error) {
      console.error('Error fetching users data:', error);
    }
  };

  const handleEdit = (rowIndex) => {
    const selectedRow = data[rowIndex];
    history.push({ pathname: `/AddHCO`, state: { user: selectedRow } });
  };

  const handleDelete = (rowIndex) => {
    const selectedRow = data[rowIndex];
    setSelectedCustomer(selectedRow);
    setOpenDialog(true);
  };

  const handleUserAction = async (index) => {
    setSelectAll(false);
    setSelectedRole("");
    setFilteredUsers(Users);
    const selectedRow = data[index];
    setSelectedIndex(index);
    setSelectedCustomer(selectedRow);
    let result = await dispatch(customerActions.GetByBranchId(selectedRow.id));
    if (result != null) {
      if (result.length == Users.length) {
        setSelectAll(true);
      }
      setCheckedUsers(
        Users.reduce((acc, user) => ({
          ...acc,
          [user.userId]: result.includes(user.userId)
        }), {})
      );
      setDialogOpen(true);
    }
  };

  const handleCloseUserDialog = () => {
    setDialogOpen(false);    // Close the dialog
  };

  const handleConfirm = async () => {
    const selectedUserIds = Object.keys(checkedUsers).filter(userId => checkedUsers[userId]);
    console.log(selectedUserIds);

    let result = await dispatch(customerActions.linkUsersOnBranch({
      Users: selectedUserIds,
      branchId: selectedCustomer.id
    }));
    if (result) {
      dispatch(alertActions.success("The Users are linked On " + selectedCustomer.name + " HCO"));
    } else {
      dispatch(alertActions.error("Error while link Users On " + selectedCustomer.name + " HCO"));
    }
    handleCloseUserDialog();
  };

  const confirmDelete = async () => {
    await dispatch(customerActions.DeleteCustomer(selectedCustomer.id));
    fetchData();
    setOpenDialog(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedCustomer(null);
  };

  const handleCheckboxChange = (event) => {
    setCheckedUsers({
      ...checkedUsers,
      [event.target.name]: event.target.checked
    });
  };

  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    setCheckedUsers(
      filteredUsers.reduce((acc, user) => ({
        ...acc,
        [user.userId]: isChecked
      }), {})
    );
  };
// Handle role selection
const handleRoleChange = (event) => {
  const role = event.target.value;
  setSelectedRole(role);
  if (role) {
    const filtered = Users.filter(user =>user.roles != null && user.roles.includes(role)); // Assuming each user has a 'role' field
    setFilteredUsers(filtered);
  } else {
    setFilteredUsers(Users); 
  }
};
  useEffect(() => {
    const fetchData = async () => {
      if (!isload) {
        try {
          let CustomersResult = await dispatch(customerActions.getAllCustomers());
          setCustomers(CustomersResult);
          setIsload(true);
          getAllUsers();
          let rolesResult = await dispatch(userActions.getAllRoles());
          setRoles(rolesResult);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };

    fetchData();
  }, [isload, dispatch]);

  return (
    <UTSLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight}>
          <Typography variant="h5" component="h1">
            HCOs
          </Typography>
        </Grid>
        <Grid item>
          <Button
            onClick={() => history.push("/AddHCO")}
            variant="outlined"
            color="primary"
            size="small"
          >
            Add HCO
          </Button>
        </Grid>
      </Grid>
      <MUIDataTable
        title={"HCO List"}
        data={data}
        columns={columns}
        options={options}
      />
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this Client?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={dialogOpen} onClose={handleCloseUserDialog}>
    <DialogTitle>Users</DialogTitle>
    <DialogContent>
      {/* Role dropdown */}
      <FormControl fullWidth>
        <InputLabel id="role-label">Filter by Role</InputLabel>
        <Select
          labelId="role-label"
          value={selectedRole}
          onChange={handleRoleChange}
        >
          <MenuItem value="">All Roles</MenuItem>
          {roles.map((role) => (
            <MenuItem key={role.title} value={role.title}>
              {role.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Select All Checkbox */}
      <FormControlLabel
        control={
          <Checkbox
            checked={selectAll}
            onChange={handleSelectAllChange}
          />
        }
        label="Select All"
      />

      {/* Filtered User List */}
      {filteredUsers.map(user => (
        <FormControlLabel
          key={user.userId}
          control={
            <Checkbox
              checked={!!checkedUsers[user.userId]}
              onChange={handleCheckboxChange}
              name={user.userId}
            />
          }
          label={user.email}
          className={classes.checkboxLabel}
        />
      ))}
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCloseUserDialog} color="primary">
        Cancel
      </Button>
      <Button onClick={handleConfirm} color="primary">
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
    </UTSLayout>
  );
};

export default AllCustomersPage;
