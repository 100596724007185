import React from "react";

import UTSNavCollapse from "./UTSNavCollapse";
import UTSNavItem from "./UTSNavItem";
import UTSNavLink from "./UTSNavLink";
import { ListSubheader } from "@material-ui/core";

const UTSNavGroup = props => {
  const { item } = props;

  return (
    <>
      <ListSubheader>{item.title}</ListSubheader>

      {item.children && (
        <React.Fragment>
          {item.children.map(item => (
            <React.Fragment key={item.id}>
              {item.type === "group" && <NavGroup item={item} />}

              {item.type === "collapse" && <UTSNavCollapse item={item} />}

              {item.type === "item" && <UTSNavItem item={item} />}

              {item.type === "link" && <UTSNavLink item={item} />}
            </React.Fragment>
          ))}
        </React.Fragment>
      )}
    </>
  );
};

const NavGroup = React.memo(UTSNavGroup);

export default NavGroup;
