import React from "react";
import UTSLayout from "../../components/UTSLayout/UTSLayout";
import { Paper, Grid, makeStyles, Typography } from "@material-ui/core";
import UTSBreadcrumbs from "../../components/UTSBreadcrumbs/UTSBreadcrumbs";
import UTSChart from "../../components/UTSChart/UTSChart";
import UTSBarChart from "../../components/UTSChart/UTSBarChart";
import UTSPieChart from "../../components/UTSChart/UTSPieChart";
import SimpleTable from "./components/SimpleTable";
import clsx from "clsx";

const useStyles = makeStyles((them) => ({
  paddingPaper: {
    padding: "10px 5px 5px 10px",
  },
  mt: {
    marginTop: 13,
  },
  titlePaper: {
    marginBottom: "16px",
  },
  visitorChart: {
    // height: "150px"
  },
}));

const DashboardPage = (props) => {
  const { history } = props;
  const classes = useStyles();

  return (
    <UTSLayout>
      <h1>Dashboard</h1>
      <iframe
        title="star repo"
        src=""
        frameworker="0"
        scrolling="0"
        width="75px"
        height="30px"
        frameBorder="none"
        style={{ marginBottom: "20px" }}
      />
       
      <Grid container spacing={2}>
        <Grid className={classes.visitorChart} item xs={12}>
          <Paper className={classes.paddingPaper} variant="outlined">
            <Typography className={classes.titlePaper} variant="h5">
              Visitors
            </Typography>
            <UTSChart />
          </Paper>
        </Grid>
        <Grid item container xs={12} sm={8}>
          <Grid item xs={12}>
            <Paper
              className={clsx(classes.paddingPaper, classes.mt)}
              variant="outlined"
            >
              <Typography className={classes.titlePaper} variant="h5">
                Foods
              </Typography>
              <SimpleTable />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper
              className={clsx(classes.paddingPaper, classes.mt)}
              variant="outlined"
            >
              <Typography className={classes.titlePaper} variant="h5">
                Sales
              </Typography>
              <UTSBarChart />
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper className={classes.paddingPaper} variant="outlined">
            <Typography className={classes.titlePaper} variant="h5">
              Customers
            </Typography>
            <UTSPieChart />
          </Paper>
        </Grid>
      </Grid>
    </UTSLayout>
  );
};

export default DashboardPage;
