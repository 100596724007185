import { authHeader } from "../redux/helpers/auth-header";
import config from "../config";
import {handleResponse,handleError} from "./handleError";
export const userService = {
  login,
  logout,
  register,
  GetAllRoles,
  GetUserProfile,
  GetAllUsers,
  GetDeletedUsers,
  GetReferringPhysicianUsers,
  UpdateUser,
  DeleteUser,
  ChangePassword,
  ForgotPassword,
  ResetPassword
};

function login(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };
  return fetch(config.baseUrl + "User/Login", requestOptions).then(
    handleResponse,
    handleError
  );
}

function logout() {
  // remove user from local storage to log user out
   sessionStorage.removeItem("token");
}

function register(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json",
      Authorization: 'Bearer ' +  sessionStorage.getItem('token')
  },
    body: JSON.stringify(user),
  };

  return fetch(config.baseUrl + "User/CreateUser", requestOptions).then(
    handleResponse,
    handleError
  );
}
function DeleteUser(userId) {
  const requestOptions = {
    method: "Get",
    headers: { "Content-Type": "application/json",
      Authorization: 'Bearer ' +  sessionStorage.getItem('token')
  },
  };

  return fetch(config.baseUrl + "User/DeleteUser?UserId="+userId, requestOptions).then(
    handleResponse,
    handleError
  );
}
function UpdateUser(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json",
      Authorization: 'Bearer ' +  sessionStorage.getItem('token')
  },
    body: JSON.stringify(user),
  };

  return fetch(config.baseUrl + "User/UpdateUser", requestOptions).then(
    handleResponse,
    handleError
  );
}
function GetAllRoles() {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json",
      Authorization: 'Bearer ' +  sessionStorage.getItem('token')
  },
    };

  return fetch(config.baseUrl + "User/GetAllRoles", requestOptions).then(
    handleResponse,
    handleError
  );

}
function GetAllUsers() {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json",
      Authorization: 'Bearer ' +  sessionStorage.getItem('token')
  },
    };

  return fetch(config.baseUrl + "User/GetAllUsers", requestOptions).then(
    handleResponse,
    handleError
  );
}
function GetDeletedUsers() {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json",
      Authorization: 'Bearer ' +  sessionStorage.getItem('token')
  },
    };

  return fetch(config.baseUrl + "User/GetDeletedUsers", requestOptions).then(
    handleResponse,
    handleError
  );
}
function GetReferringPhysicianUsers(branchId) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json",
      Authorization: 'Bearer ' +  sessionStorage.getItem('token')
  },
    };

  return fetch(config.baseUrl + "User/GetReferringPhysicianUsers/"+branchId, requestOptions).then(
    handleResponse,
    handleError
  );
}
function GetUserProfile(userId){
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json",
      Authorization: 'Bearer ' +  sessionStorage.getItem('token')
  },
    };

  return fetch(config.baseUrl + "User/GetUserProfile/"+userId, requestOptions).then(
    handleResponse,
    handleError
  );

}
function ChangePassword(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json",
      Authorization: 'Bearer ' +  sessionStorage.getItem('token')
  },
    body: JSON.stringify(user),
  };

  return fetch(config.baseUrl + "User/ChangePassword", requestOptions).then(
    handleResponse,
    handleError
  );
}
function ResetPassword(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json",
      Authorization: 'Bearer ' +  sessionStorage.getItem('token')
  },
    body: JSON.stringify(user),
};

  return fetch(config.baseUrl + "User/ResetAdminPassword", requestOptions).then(
    handleResponse,
    handleError
  );
}
function ForgotPassword(email){
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetch(config.baseUrl + "User/SendForgotPasswordEmail?email="+email, requestOptions).then(
    handleResponse,
    handleError
  );
}

