import React, { useState, useRef, useEffect } from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { userActions } from "../../../redux/actions/user";
import {customerActions} from "../../../redux/actions/customer";
import { useDispatch } from 'react-redux';
import AddAutoComplete from "../../../components/extra/Controls/AddAutoComplete";
import { useSelector } from 'react-redux';
import {
  Button,
  Divider,
  ExpansionPanelActions,
} from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import UTSLayout from "../../../components/UTSLayout/UTSLayout";
import UTSBreadcrumbs from "../../../components/UTSBreadcrumbs/UTSBreadcrumbs";
import { Typography, Grid, makeStyles, TextField } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  my3: {
    margin: "1.3rem 0"
  },
  mb3: {
    margin: "1.3rem 0"
  },
  mb0: {
    marginBottom: 0
  },
  mRight: {
    marginRight: ".85rem"
  },
  p1: {
    padding: ".85rem"
  }
}));

const AddCustomerPage = props => {
  const [expanded, setExpanded] = React.useState(true);
  const [errorName, setNameError] = useState('');
  const [errorCode, setCodeError] = useState('');
  const [errors, setErrors] = useState([]);
  const [isload, setIsload] = React.useState(false);
  const dispatch = useDispatch();
  const historyRef = useHistory();
  let [user, setUser] = useState(props.location.state?.user ?? {});
  const currentUser =userActions.getLoginUser();
  function getUserData(e) {
    let copyUser = { ...user };
    copyUser[e.target.name] = e.target.value;
    setUser(copyUser);
  }

  const handleExpandedChange = () => {
    setExpanded(!expanded);
  };

  const { history } = props;
  const classes = useStyles();
  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors([]);
    let isValid = ValidateForm();
    if (isValid) {
      let result = null;
      if (user.id == null || user.id == "") {
        user.createdBy = currentUser.email;
        result = await dispatch(customerActions.AddCustomer(user));
      } else {
        user.lastUpdatedBy = currentUser.email;
        result = await dispatch(customerActions.UpdateCustomer(user));
      }
      if (result != null) {

          historyRef.push("/HCO")
      }else{
         setErrors([{
          description:"Error While saving data please check if customer name and code are existed."
         }])
      }
    }

    function ValidateForm() {
      let isValid = true;
      if (user.name == "" || user.name == null) {
        isValid = false;
        setNameError('Name is required.');
      } else {
        setNameError('');
      }
      if (user.code == "" || user.code == null) {
        isValid = false;
        setCodeError('Code is required.');
      } else {
        setCodeError('');
      }
      return isValid;
    }
  };
  return (
    <UTSLayout>
      <Typography className={classes.mb3} variant="h5" component="h1">
        {user.id != null?"Edit HCO": "Add New HCO"}
      </Typography>
       
      <div className={classes.root} >
        <Grid container spacing={3}>

          <Grid item xs={12} md={8}>
            <div className={classes.root}>
              {errors.length > 0 ? (
                <ExpansionPanel expanded={true} className={classes.mb3}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className={classes.heading}>Errors</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid container spacing={2}>
                      {errors.map((error, index) => (
                        <Grid item xs={12} md={12} key={index}>
                          <label style={{ color: 'red' }}>{error.description}</label>
                        </Grid>
                      ))}
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ) : null}
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3}>

          <Grid item xs={12} md={8}>
            <div className={classes.root}>

              <ExpansionPanel expanded={expanded} className={classes.mb3}>
                <ExpansionPanelSummary
                  onClick={handleExpandedChange}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>HCO{':  '+(user?.name==null?'':user?.name)}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid container spacing={0}>
                    <Grid item xs={12} md={12}>
                      <TextField
                        name="name"
                        label="Name"
                        className={classes.mb3}
                        placeholder="Enter Name here"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true
                        }}
                        value={user.name}
                        onChange={getUserData}
                        error={!!errorName}
                        helperText={errorName}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                    <TextField
                        name="code"
                        label="Code"
                        className={classes.mb3}
                        placeholder="Enter Code here"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true
                        }}
                        value={user.code}
                        onChange={getUserData}
                        error={!!errorName}
                        helperText={errorName}
                        inputProps={{
                          maxLength: 4 // Set the maximum length to 4 characters
                        }}
                      />
                    </Grid>
                  </Grid>
                </ExpansionPanelDetails>
                <Divider />
                <ExpansionPanelActions>
                  <Grid container justify="space-between">
                    <Button style={{ visibility: 'none' }} size="small">
                    </Button>
                    <Button variant="contained" onClick={handleSubmit} color="primary" size="small">
                      Save
                    </Button>
                  </Grid>
                </ExpansionPanelActions>
              </ExpansionPanel>

            </div>
          </Grid>
        </Grid>
      </div>
    </UTSLayout>
  );
};

export default AddCustomerPage;
