import UsersPage from "./UsersPage";

export const UsersPageConfig = {
  routes: [
    {
      path: "/users",
      exact: true,
      component: UsersPage,
      protected: true,
      roles:['Admin']
    }
  ]
};
