import LoginPage from "./LoginPage";

export const LoginPageConfig = {
  routes: [
    {
      path: "/login",
      exact: true,
      component: LoginPage,
      protected: false,
    }
  ]
};
