import {handleResponse,handleError} from "../../services/handleError";
// import jwt from 'jwt-decode';
import config from "../../config";
import axios from "axios";
export const customerActions = {
    getAllCustomers,
    DeleteCustomer,
    UpdateCustomer,
    AddCustomer,
    getUploadData,
    SetUploadData,
    GetByUserId,
    linkUsersOnBranch,
    GetByBranchId
}

function getAllCustomers() {
    return  async(dispatch) => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json",
                Authorization: 'Bearer ' +  sessionStorage.getItem('token')
            }
            
        };
        
        let result=  await fetch(config.baseUrl + "Branch/GetAllBranchs", requestOptions).then(
            handleResponse,
            handleError
          );
           return result;
    };
}
function GetByUserId(userId) {
    return  async(dispatch) => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json",
                Authorization: 'Bearer ' +  sessionStorage.getItem('token')
            }
        };
        
        let result=  await fetch(config.baseUrl + "Branch/GetByUserId?userId="+userId, requestOptions).then(
            handleResponse,
            handleError
          );
           return result;
    };
}
function GetByBranchId(branchId) {
    return  async(dispatch) => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json",
                Authorization: 'Bearer ' +  sessionStorage.getItem('token')
            }
        };
        
        let result=  await fetch(config.baseUrl + "Branch/GetByBranchId?branchId="+branchId, requestOptions).then(
            handleResponse,
            handleError
          );
           return result;
    };
}
function DeleteCustomer(CustomerId) {
    return  async(dispatch) => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json",
                Authorization: 'Bearer ' +  sessionStorage.getItem('token')
            }
            };
        
        let result=  await fetch(config.baseUrl + "Branch/DeleteBranch?BranchId="+CustomerId, requestOptions).then(
            handleResponse,
            handleError
          );
           return result;
    };
}
function UpdateCustomer(Branch) {
    return  async(dispatch) => {
        try{
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json",
                Authorization: 'Bearer ' +  sessionStorage.getItem('token')
            },
            body: JSON.stringify(Branch),
            };
        
        let result=  await fetch(config.baseUrl + "Branch/UpdateBranch", requestOptions).then(
            handleResponse,
            handleError
          );
           return result;
        }catch(err){
            return null;
        }
    };
}
function AddCustomer(Branch) {
    return  async(dispatch) => {
        try{
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json",
                Authorization: 'Bearer ' +  sessionStorage.getItem('token')
            },
            body: JSON.stringify(Branch),
            };
        
        let result=  await fetch(config.baseUrl + "Branch/AddBranch", requestOptions).then(
            handleResponse,
            handleError
          );
         
           return result;
        }catch(ex){
            return null;
        }
    };
}
function linkUsersOnBranch(model) {
    return  async(dispatch) => {
        try{
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json",
                Authorization: 'Bearer ' +  sessionStorage.getItem('token')
            },
            body: JSON.stringify(model),
            };
        
        let result=  await fetch(config.baseUrl + "Branch/linkUsersOnBranch", requestOptions).then(
            handleResponse,
            handleError
          );
         
           return result;
        }catch(ex){
            return null;
        }
    };
}
let uploadData={}
function SetUploadData(userId,customerId,customerName){
    uploadData={
        userId:userId,
        customerId:customerId,
        customerName:customerName
    }
}
function getUploadData(){
    return uploadData
}