import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function AddAutoComplete(props) {
  return (
    <Autocomplete
      multiple={props.multiple}
      size="small"
      aria-required={props.require}
      disableCloseOnSelect={props.multiple}
      value={
       
        props.multiple
          ? props?.data?.filter(option => props.value?.includes(option.id) ) || null
          : props?.data?.find(option => option.id === props.value) || null
      }
      // Ensure that options is always an array, even if props.data is undefined
      options={props.data || []}
      onChange={(event, newValue) => {
        props.getValue(newValue);
      }}
      getOptionLabel={(option) => option[props.displayName]}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          {props.multiple ? (
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
          ) : null}
          {option[props.displayName]}
        </React.Fragment>
      )}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={props.label}
          placeholder={props.label}
          error={!!props.error}
          helperText={props.helperText}
        />
      )}
    />
  );
}
