import React, { useState, useRef, useEffect } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    makeStyles,
    IconButton
} from "@material-ui/core";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { useDispatch } from 'react-redux';
import { studiesActions } from "../../../../redux/actions/study";
import { userActions } from "../../../../redux/actions/user";
import { green } from '@material-ui/core/colors';
import { templatesActions } from "../../../../redux/actions/template";
import {  Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
const useStyles = makeStyles(theme => ({
    my3: {
      margin: "0rem 0"
    },
    mb0: {
      marginBottom: 0
    },
    mRight: {
      marginRight: ".85rem"
    },
    p1: {
      padding: ".05rem"
    }
  }));
const TemplateDialog = ({ open, onClose, selectedStudy, setProgressWord, setLoading, setIsRefresh, isRefresh }) => {
    const classes = useStyles();
    const TemplateOptions = {
        filterType: 'checkbox',
        responsive: 'standard',
        customHeadRender: () => null, // Hides the header
        selectableRows: 'none', // Optional: Hide row selection
        print: false,
        download: false,
        viewColumns: false,
      };
    
    const templateColumns = [
        {
          name: "name",
          label: "Name",
          options: {
            filter: true,
            sort: true,
          },
        },
        {
          name: "actions",
          label: "Actions",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta) => {
              return (
                <>
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => handleDownloadSelectedTemplate(tableMeta.rowIndex)}
                  >
                    <CloudDownloadIcon style={{ color: "#4CAF50" }} />
                  </IconButton>
                </>
              );
            },
          },
        },
      ];
    const dispatch = useDispatch();
    const currentUser = userActions.getLoginUser();
    const [templates, setTemplates] =React. useState([]);
    const fetchTemplateData = async () => {
        try {
          let TemplatesResult = await dispatch(templatesActions.GetAllTemplates());
          setTemplates(TemplatesResult);
        //   setTemplateLoaded(true);
        } catch (error) {
          console.error("Error fetching template data:", error);
        }
      };
      const handleDownloadSelectedTemplate = async (rowIndex) => {
        const selectedRow = templates[rowIndex];
        let uploadResult = await dispatch(templatesActions.DownloadTemplate(selectedRow.name, selectedStudy.patientId));
        // setIsload(false);
        // setIsload(true);
       // setSelectedStudy(null);
        //setopenTemplateDialog(false);
    
      };
    useEffect(() => {
        // if (!isTemplateLoaded) {
          fetchTemplateData();
        //}
      }, []);
    return (

        <Dialog
          open={open}
          onClose={onClose}>
          <DialogTitle>Templates</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Grid container className={classes.my3} alignItems="center">
                <MUIDataTable
                  title={"Templates List"}
                  data={templates}
                  columns={templateColumns}
                  options={TemplateOptions}
                />
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
    );
};

export default TemplateDialog;
