import React from 'react';
import { useSelector } from 'react-redux';
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import{userActions} from "../../redux/actions/user";

import navigationConfig from "../../UTS-configs/navigationConfig";

import UTSNavGroup from "./sections/UTSNavGroup";
import UTSNavCollapse from "./sections/UTSNavCollapse";
import UTSNavItem from "./sections/UTSNavItem";
import UTSNavLink from "./sections/UTSNavLink";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  logoBg: {
    backgroundColor: theme.palette.type !== "dark" ? "#18202c" : "inherit",
  },
  logo: {
    padding: "1rem",
    "& span": {
      display: "block",
      color: "rgba(41, 113, 245, 0.87)",
    },
  },
  navCustom: {
    "& .MuiTypography-root": {
      fontSize: ".85rem",
    },
    "& .MuiListItemIcon-root": {
      minWidth: "35px",
    },
    "& .MuiCollapse-wrapperInner a": {
      paddingLeft: "50px",
    },
  },
}));

const UTSNavigation = (props) => {
  const classes = useStyles(props);
  const currentUser = userActions.getLoginUser();

  const getFilteredNavigationConfig = () => {
    if (!currentUser || !currentUser.roles) {
      return navigationConfig;
    }

    return navigationConfig
      .map((group) => ({
        ...group,
        children: group.children
          ? group.children
              .filter((item) => !item.roles || item.roles.some(role => currentUser.roles.includes(role)))
              .map((item) => ({
                ...item,
                children: item.children
                  ? item.children.filter((child) => !child.roles || child.roles.some(role => currentUser.roles.includes(role)))
                  : [],
              }))
          : [],
      }))
      .filter((group) => group.children.length > 0); // Remove groups with no accessible items
  };

  const filteredNavigationConfig = getFilteredNavigationConfig();

  return (
    <div>
      <div className={clsx(classes.toolbar, classes.logoBg)}>
        <Typography className={classes.logo} variant="h6" component="h1" align="center">
          <img 
            src="https://unitedtelescans.com/wp-content/uploads/2024/07/LOGO-01-1-1.png" 
            alt="logo" 
            id="loginLogo" 
            style={{ width: '8rem' }} 
            onClick={() => window.location.href = 'https://unitedtelescans.com/'}
            />
        </Typography>
      </div>
      <Divider />
      <List className={classes.navCustom}>
        {filteredNavigationConfig.map((item) => (
          <React.Fragment key={item.id}>
            {item.type === "group" && <UTSNavGroup item={item} />}
            {item.type === "collapse" && <UTSNavCollapse item={item} />}
            {item.type === "item" && <UTSNavItem item={item} />}
            {item.type === "link" && <UTSNavLink item={item} />}
            {item.type === "divider" && <Divider className="my-16" />}
          </React.Fragment>
        ))}
      </List>
    </div>
  );
};

export default UTSNavigation;
