import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useStyles } from './styles';
import FileRow from './FileRow';
import { Chip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { FixedSizeList as List } from 'react-window';
import { useDispatch } from 'react-redux';
import { alertActions } from "../../../src/redux/actions/alert.actions";
import {  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";


export default function UploadingTab(props) {
  const { selectedFiles } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [openUploadingTabDialog, setopenUploadingTabDialog] = useState(false);

  const uploadAnotherPage = async (event) => {
    event.preventDefault();
    props.backOnSelectFiles();
  };

  const handleCloseUploadingTabDialog=async()=>{
    setopenUploadingTabDialog(false);
  }

  useEffect(() => {
    if (
      selectedFiles.filter((file) => file.status === 'Success' || file.status === 'Fail').length === selectedFiles.length
      && selectedFiles.filter((file) => file.status === 'Success').length>0) {
      setopenUploadingTabDialog(true);
    }
  }, [selectedFiles, dispatch]);

  const Row = ({ index, style }) => (
    <div style={{ ...style }}>
      <FileRow key={index} file={selectedFiles[index]} />
    </div>
  );

  return (
    <>
      <Typography variant="body1" gutterBottom className={classes.titleText}>
        <Box key={"uploadAnotherPage"} onClick={uploadAnotherPage}>
          <Chip label="Upload Other Files" style={{ backgroundColor: "#1332a7", color: 'white' }} size="medium" />
        </Box>
      </Typography>
      <Card style={{ height: '23.5rem', marginTop: '8px', margin: '8px' }}>
        <CardContent style={{ height: '100%', padding: '0px', overflowY: 'auto' }}>
          <Typography
            variant="h2"
            gutterBottom
            className={classes.titleText}
            style={{
              fontSize: '1rem',
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontWeight: 400,
              lineHeight: 1.5,
              letterSpacing: '0.00938em',
            }}
          >
            {`Uploading ${selectedFiles.length} files`}
          </Typography>

          <Box className={classes.uploadingTab}>
            <Box className={classes.uploaderTab}>
              <List height={300} itemCount={selectedFiles.length} itemSize={90} width={'100%'}>
                {Row}
              </List>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Typography variant="body1" gutterBottom className={classes.titleText}>
        {`${selectedFiles.filter((file) => file.status === 'Success').length} files uploaded successfully`}
      </Typography>
      <Dialog
          open={openUploadingTabDialog}
          onClose={handleCloseUploadingTabDialog}
        >
          <DialogTitle>Attention:</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div> To streamline our reporting process, please ensure that all uploaded DICOM files are assigned to a designated referring physician. Either:</div> 
               <div>1. Select a pre-assigned physician</div> 
               <div>2. Create a new physician profile. </div> 
                <br></br>
               <div>This step is essential for accurate and efficient report generation.</div> 
               <br></br>
               <div>Thank you for your cooperation in helping us provide timely, high-quality radiology reports.</div> 
               <br></br>
              <div>United Tele Scans Team </div>  
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseUploadingTabDialog} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
    </>
  );
}
