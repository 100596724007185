import React, { useState, useRef, useEffect } from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { userActions } from "../../../redux/actions/user";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  Button,
  Divider,
  ExpansionPanelActions,
} from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import UTSLayout from "../../../components/UTSLayout/UTSLayout";
import UTSBreadcrumbs from "../../../components/UTSBreadcrumbs/UTSBreadcrumbs";
import { Typography, Grid, makeStyles, TextField } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  my3: {
    margin: "1.3rem 0"
  },
  mb3: {
    margin: "1.3rem 0"
  },
  mb0: {
    marginBottom: 0
  },
  mRight: {
    marginRight: ".85rem"
  },
  p1: {
    padding: ".85rem"
  }
}));
const roles = [
  { id: "Admin", title: "Admin" },
  { id: "Doctor", title: "Doctor" },
  // { title: "react" },
  // { title: "material ui" }
];
const ChangePasswordPage = props => {
  const [expanded, setExpanded] = React.useState(true);
  const [errorConfirmationPassword, setConfirmationPassword] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [errors, setErrors] = useState('');
  const dispatch = useDispatch();
  const historyRef = useHistory();
  let [user, setUser] = useState(props.location.state?.user ?? {});
  const currentUser =userActions.getLoginUser();
  function getUserData(e) {
    let copyUser = { ...user };
    copyUser[e.target.name] = e.target.value;
    setUser(copyUser);
  }
  const handleExpandedChange = () => {
    setExpanded(!expanded);
  };

  const { history } = props;
  const  classes = useStyles();
  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors('');
    let isValid = ValidateForm();
    if (isValid) {
      user.createdBy = currentUser.email;
      let result = null;
      user.userId=currentUser.userId;
      result = await dispatch(userActions.changePassword(user));
      if (result != null) {
        if (result.success) {
          await dispatch(userActions.logout());
        } else {
          setErrors(result.message);
        }
      }else{
        setErrors('Error While Change password.');
      }
    }

    function ValidateForm() {
      let isValid = true;

      if (user.password !== user.confirmPassword) {
        isValid = false;
        setConfirmationPassword('Passwords do not match.');
      } else {
        setConfirmationPassword('');
      }

      if (user.password == "" || user.password == null) {
        isValid = false;
        setErrorPassword('Password is required.');
      } else {
        setErrorPassword('');
      }
      if (user.currentPassword == "" || user.currentPassword == null) {
        isValid = false;
        setErrorPassword('Password is required.');
      } else {
        setErrorPassword('');
      }
      
      return isValid;
    }
  };
  return (
    <UTSLayout>
      <Typography className={ classes?.mb3} variant="h5" component="h1">
        Change Password
      </Typography>
      {/*   */}
      <div className={ classes?.root} >
        <Grid container spacing={3}>

          <Grid item xs={12} md={8}>
            <div >
              {errors != null && errors.length > 0 ? (
                <ExpansionPanel expanded={true} className={ classes?.mb3}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className={ classes?.heading}>Errors</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} >
                          <label style={{ color: 'red' }}>{errors}</label>
                        </Grid>
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ) : null}
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3}>

          <Grid item xs={12} md={8}>
            <div className={ classes?.root}>

              <ExpansionPanel expanded={expanded} className={ classes?.mb3}>
                <ExpansionPanelSummary
                  onClick={handleExpandedChange}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={ classes?.heading}>Change Password</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid container spacing={0}>
                  <Grid item xs={12} md={12}>
                        <TextField
                          name="currentPassword"
                          label="Current Password"
                          type="password"
                          className={ classes?.mb3}
                          placeholder="Enter Current Password here"
                          fullWidth
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          autoComplete="new-password"
                          value={user.currentPassword}
                          onChange={getUserData}
                          error={!!errorPassword}
                          helperText={errorPassword}
                        />
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <TextField
                          name="password"
                          label="Password"
                          type="password"
                          className={ classes?.mb3}
                          placeholder="Enter Password here"
                          fullWidth
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          autoComplete="new-password"
                          value={user.password}
                          onChange={getUserData}
                          error={!!errorPassword}
                          helperText={errorPassword}
                        />
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <TextField
                          name="confirmPassword"
                          label="Confirm Password"
                          type="password"
                          className={ classes?.mb3}
                          placeholder="Confirm Password here"
                          fullWidth
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={user.confirmPassword}
                          onChange={getUserData}
                          error={!!errorConfirmationPassword}
                          helperText={errorConfirmationPassword}
                          autoComplete="new-password"
                        />
                      </Grid>
                  </Grid>
                </ExpansionPanelDetails>
                <Divider />
                <ExpansionPanelActions>
                  <Grid container justify="space-between">
                    <Button style={{ visibility: 'none' }} size="small">
                    </Button>
                    <Button variant="contained" onClick={handleSubmit} color="primary" size="small">
                      Save
                    </Button>
                  </Grid>
                </ExpansionPanelActions>
              </ExpansionPanel>

            </div>
          </Grid>
        </Grid>
      </div>
    </UTSLayout>
  );
};

export default ChangePasswordPage;
