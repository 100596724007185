import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute'; // Adjust the import path
import routes from '../../UTS-configs/routesConfig';
import NotificationAlert from "../../services/NotificationAlert";

const useAuth = () => {
  const isAuthenticated = sessionStorage.getItem("token") != null; 
  return { isAuthenticated };
};

const UTSRoutes = () => {
  const { isAuthenticated } = useAuth();
  return (
    <Router>
      <NotificationAlert />
      <Switch>
        {routes.map((route, index) => {
          if (route.protected) {
            return (
              <ProtectedRoute
                key={index}
                path={route.path}
                roles={route.roles}
                component={route.component}
                isAuthenticated={isAuthenticated}
                exact={route.exact}
              />
            );
          }
          return (
            <Route
              key={index}
              {...route}
              exact
            />
          );
        })}
      </Switch>
    </Router>
  );
};

export default UTSRoutes;
