import React, { useState, useRef, useEffect } from "react";
import UTSLayout from "../../../components/UTSLayout/UTSLayout";
import UTSBreadcrumbs from "../../../components/UTSBreadcrumbs/UTSBreadcrumbs";
import AddAutoComplete from "../../../components/extra/Controls/AddAutoComplete";
import { Grid, makeStyles, IconButton, Button } from "@material-ui/core";
import {
  TextField,
  FormControl,
  CircularProgress
} from "@material-ui/core";
import LinearProgressWithLabel from "../../../components/UTSProgress/LinearProgressWithLabel"
import { alertActions } from "../../../redux/actions/alert.actions";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DescriptionIcon from '@material-ui/icons/Description';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import PersonIcon from '@material-ui/icons/Person';
import ReferringPhysicianDialog from "./ReferringPhysician/ReferringPhysicianDialog";
import MUIDataTable from "mui-datatables";
import { studiesActions } from "../../../redux/actions/study";
import { userActions } from "../../../redux/actions/user";
import { useDispatch } from 'react-redux';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { green, orange, blue, grey } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { Forward } from "@material-ui/icons";
import UploadReportDialog from "./Report/UploadReportDialog";
import ApproveDialog from "./Report/ApproveDialog";
import CompleteDialog from "./Report/CompleteDialog";
import TemplateDialog from "./Report/TemplateDialog";
import ChangeColumnDialog from "./Columns/ChangeColumnDialog";

const useStyles = makeStyles(theme => ({
  my3: {
    margin: "0rem 0"
  },
  mb0: {
    marginBottom: 0
  },
  mRight: {
    marginRight: ".85rem"
  },
  p1: {
    padding: ".05rem"
  }
}));





const modalities = [
  { id: "CR", name: "CR" },
  { id: "CT", name: "CT" },
  { id: "DX", name: "DX" },
  { id: "IO", name: "IO" },
  { id: "MG", name: "MG" },
  { id: "MR", name: "MR" },
  { id: "NM", name: "NM" },
  { id: "OT", name: "OT" },
  { id: "PR", name: "PR" },
  { id: "PT", name: "PT" },
  { id: "SR", name: "SR" },
  { id: "US", name: "US" },
];
const AllStudiesPage = props => {
  const { history } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isload, setIsload] = React.useState(false);
  const [isRefresh, setIsRefresh] = React.useState(false);
  const [data, setStudies] = React.useState([]);
  const [openCompleteDialog, setOpenCompleteDialog] = useState(false);
  const [openTemplateDialog, setopenTemplateDialog] = useState(false);
  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [openUploadReportDialog, setOpenUploadReportDialog] = useState(false);
  const [openChangeColumnDialog, setOpenChangeColumnDialog] = useState(false);
  const [selectedStudy, setSelectedStudy] = useState(null);
  const [page, setPage] = useState(0); // 0-based index for MUIDataTable
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [totalCount, setTotalCount] = useState();
  const [uploadFile, setUploadFile] = useState(null);
  const [loading, setLoading] = useState({
    download: false,
    approve: false,
    complete: false,
    templateDownload: false,
  });
  const currentUser = userActions.getLoginUser();
  const [patientId, setPatientId] = useState("");
  const [patientName, setPatientName] = useState("");
  const [accessionNumber, setAccessionNumber] = useState("");
  const [modality, setModality] = useState([]);
  const [branch, setBranch] = useState(currentUser.customers);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [sortColumn, setSortColumn] = useState("StudyDate");
  const [sortDirection, setSortDirection] = useState("ASC");
  const [progressWord, setProgressWord] = useState("");
  const [openReferringDialog, setOpenReferringDialog] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);

  function getModalityData(e) {
    setModality(e.map(x => x.id));
  }
  const handleDownloadImage = async (view = false) => {
    setLoading((prev) => ({ ...prev, download: true }));
    setProgressWord("Collecting Images");
    const prepareTimer = setTimeout(() => {
      setProgressWord("Preparing Images");
    }, 30000);
    const compressTimer = setTimeout(() => {
      setProgressWord("Compressing Images");
    }, 120000);
    try {
      let result = await dispatch(studiesActions.downloadFolderAsZip(selectedStudy.customerName + '/' + selectedStudy.patientCode + '/' + selectedStudy.studyInstanceUID, selectedStudy.patientId, view, selectedStudy.studyInstanceUID));
      if (!result) {
        dispatch(alertActions.error("Error downloading image"));
      }
      setProgressWord("");
    } catch (error) {
      console.error("Download error:", error);
    } finally {
      clearTimeout(prepareTimer);
      clearTimeout(compressTimer);

      dispatch(alertActions.clear());
      setLoading((prev) => ({ ...prev, download: false }));
    }
  };

  const handleApprove = async () => {
    setOpenApproveDialog(true);
  };



  const handleCloseApproveDialog = () => {
    setOpenApproveDialog(false);
  }
  const handleCloseUploadReportDialog = () => {
    setOpenUploadReportDialog(false);
  }

  const handleComplete = async () => {
    setOpenCompleteDialog(true);
  };
  const handleCloseCompleteDialog = () => {
    setOpenCompleteDialog(false);
  }

  const handleDownloadWordReport = async (isPDF = false) => {
    if (isPDF) setProgressWord("Downloading PDF Report");
    else setProgressWord("Downloading Word Report");
    let template = await dispatch(studiesActions.DownloadReport({
      BranchId: selectedStudy.branchId,
      BranchName: selectedStudy.customerName,
      patientCode: selectedStudy.patientCode,
      StudyId: selectedStudy.studyIncId,
      StudyInstanceUID: selectedStudy.studyInstanceUID,
      isPDF: isPDF,
      studyCode: selectedStudy.branchName
    }));
    setProgressWord("");
  }
  const handleDownloadTemplate = async () => {
    setopenTemplateDialog(true);
  };

  const handleCloseTemplateDialog = () => {
    setopenTemplateDialog(false);
  }
  const handlePageChange = (currentPage) => {
    setPage(currentPage);
    fetchData(currentPage + 1, rowsPerPage);

  };
  const handleRowsPerPageChange = (numberOfRows) => {
    setRowsPerPage(numberOfRows);
    setPage(0); 
    fetchData(1, numberOfRows);
  };
  const handleColumnSortChange = (changedColumn, direction) => {
    setSortColumn(changedColumn);
    setSortDirection(direction == "descending" ? "desc" : "asc");
  };
  const handleOpenReferringDialog = (index) => {
    setOpenReferringDialog(true);
  };

  const handleCloseReferringDialog = () => {
    setOpenReferringDialog(false);
    setIsRefresh(!isRefresh);
  };
 const handleCloseChangeColumnDialog=(columns)=>{
  setVisibleColumns(columns);
  setOpenChangeColumnDialog(false);
 }

  const columns = [
    {
      name: "patientId",
      label: "Patient ID",
      options: {
        filter: true,
        sort: true,

      }
    },

    ...(currentUser.roles && (currentUser.roles.includes('Admin')
      || currentUser.roles.includes('Client') || currentUser.roles.includes('Referring Physician')) ? [{
        name: "patientName",
        label: "Patient Name",
        options: {
          filter: true,
          sort: true
        }
      }] : []),

    {
      name: "birthDate",
      label: "DOB",
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "sex",
      label: "Gender",
      options: {
        filter: true,
        sort: true
      }
    },
    ...(currentUser.roles && (currentUser.roles.includes('Admin')
      || currentUser.roles.includes('Client')) ? [{
        name: "referringPhysician",
        label: "Referring Physician",
        options: {
          filter: true,
          sort: true
        }
      }] : []),
    ...(currentUser.roles && (currentUser.roles.includes('Admin')) ? [{
      name: "studyIncId",
      label: "Study Code",
      options: {
        filter: true,
        sort: true,
        display: false
      }
    }] : []),
    {
      name: "reportStatus",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          let icon = null;
          let color = '';
          const isMobileStatus = window.innerWidth <= 600;
          const iconFontStatusSize = isMobileStatus && 13;
          switch (value) {
            case 'Report Pending':
              icon = <HourglassEmptyIcon style={{ color: orange[500], fontSize: iconFontStatusSize }} />;
              color = orange[500];
              break;
            case 'Forward for review':
              icon = <Forward style={{ color: blue[500], fontSize: iconFontStatusSize }} />;
              color = blue[500];
              break;
            case 'Report Completed':
              icon = <CheckCircleIcon style={{ color: green[500], fontSize: iconFontStatusSize }} />;
              color = green[500];
              break;
            default:
              icon = <HourglassEmptyIcon style={{ color: grey[500], fontSize: iconFontStatusSize }} />;
              color = grey[500];
              break;
          }

          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {icon}
              <span style={{ marginLeft: 8, color, fontSize: iconFontStatusSize }}>{value}</span>
            </div>
          );
        }
      }
    },
    {
      name: "accessionNumber",
      label: "AccessionNumber",
      options: {
        filter: true,
        sort: true,
        display: false
      }
    },
    {
      name: "studyDate",
      label: "Exam Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (value) {
            const date = new Date(value);
            const formattedDate =
              ('0' + (date.getMonth() + 1)).slice(-2) + '/' + // MM
              ('0' + date.getDate()).slice(-2) + '/' +        // DD
              date.getFullYear();                             // YYYY
            return formattedDate;
          }
          return "N/A"; // In case the date value is null or undefined
        }
      }
    },
    {
      name: "modality",
      label: "Modality",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            textAlign: 'center',
            backgroundColor: 'transparent'
          }
        })
      }
    },

    {
      name: "branchName",
      label: "UTS Code",
      options: {
        filter: true,
        sort: true,
        display: false
      }
    }
    // {
    //   name: "actions",
    //   label: "Actions",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (value, tableMeta) => {
    //       const isMobile = window.innerWidth <= 600;
    //       const iconFontSize = isMobile && 16;

    //       return (

    //       );
    //     }
    //   }
    // }


  ];
  const [visibleColumns, setVisibleColumns] = useState(columns);

  const fetchData = async (pageNumber = 1, pageSize = 10) => {
    try {
      const params = {
        PatientId: patientId,
        PatientName: patientName,
        AccessionNumber: accessionNumber,
        Modality: modality,
        Branch: currentUser.roles.includes('Admin') ? [] : currentUser.customers,
        StartDate: startDate,
        EndDate: endDate,
        PageNumber: pageNumber,
        PageSize: pageSize,
        SortColumn: sortColumn,
        SortDirection: sortDirection,
        Role: currentUser.roles[0],
        UserId: currentUser.userId
      };

      const { studies, totalCount } = await dispatch(
        studiesActions.getAllStudies(params)
      );

      setStudies(studies);
      if (studies.length > 0) {
        setSelectedRowIndex(0);
        const selectedRow = studies[0];
        setSelectedStudy(selectedRow);
      }
      setTotalCount(totalCount);
    } catch (error) {
      console.error("Error fetching studies data:", error);
    }
  };

  const fileInputRef = useRef(null);

  const handleEdit = () => {
    setUploadFile(null);
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadFile(file);
      if (selectedStudy.branchName != file.name) {
        setOpenUploadReportDialog(true);
        return;
      } else {
        await UploadReport();
      }
    }
  };
  const UploadReport = async () => {
    setOpenUploadReportDialog(false);
    setProgressWord("Uploading Report");
    let uploadResult = await dispatch(studiesActions.uploadReport(uploadFile,
      selectedStudy.branchId,
      selectedStudy.customerName,
      selectedStudy.patientCode,
      selectedStudy.studyIncId,
      selectedStudy.studyInstanceUID,
      currentUser.userId,
      selectedStudy.branchName
    ));

    if (uploadResult == true) {
      dispatch(alertActions.success('The file upload successfully.'));
    } else {
      dispatch(alertActions.error("The file upload failed."));
    }
    setProgressWord("");

  }


  useEffect(() => {

    if (currentUser != null && currentUser.userId != null) {
      setPage(0);
      fetchData(1, rowsPerPage);
    }
  }, [patientId, patientName, accessionNumber, modality, startDate, endDate, sortColumn, sortDirection, isRefresh]);
  // const handleViewDetails = () => {
  //   if (selectedRowIndex >= 0) {
  //     const selectedRow = data[selectedRowIndex];
  //     console.log("Viewing details for:", selectedRow);
  //   }
  // };
  const handleViewDetails = async () => {
    handleDownloadImage(true);
  };
  const handleOHIFViewDetails=async ()=>{
    setProgressWord("Collecting Images");
    const prepareTimer = setTimeout(() => {
      setProgressWord("Preparing viewer");
    }, 100000);

    try {
      let result = await dispatch(studiesActions.getOHIFViewerId(selectedStudy.customerName + '/' + selectedStudy.patientCode + '/' + selectedStudy.studyInstanceUID, selectedStudy.patientId, selectedStudy.studyInstanceUID));
      if (!result) {
        dispatch(alertActions.error("Error While open OHIFViewer"));
      }
      setProgressWord("");
    } catch (error) {
      console.error("Viewer error:", error);
    } finally {
      clearTimeout(prepareTimer);
      dispatch(alertActions.clear());
      setLoading((prev) => ({ ...prev, download: false }));
    }
  }
  const options = {
    filterType: "checkbox",
    selectableRows: "none", // Change to single selection
    rowsPerPage,
    rowsPerPageOptions: [5, 10, 15, 20],
    count: totalCount,
    page,
    serverSide: true,
    onChangePage: handlePageChange,
    onChangeRowsPerPage: handleRowsPerPageChange,
    onColumnSortChange: handleColumnSortChange,
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    selectableRowsHideCheckboxes: true,
    onRowSelect: (rowsSelected) => {
      const selectedRowIndex = rowsSelected.data[0].dataIndex;
      const selectedRow = data[selectedRowIndex];
      setSelectedRowIndex(selectedRowIndex); // Update selected row index
      setSelectedStudy(selectedRow);
    },
    customRowRender: (rowData, rowIndex) => {
      const isSelected = rowIndex === selectedRowIndex;
      const handleRowClick = () => {
        setSelectedRowIndex(rowIndex);
        const selectedRow = data[rowIndex];
        setSelectedStudy(selectedRow);
      };
    
      const modalityValue = rowData.find((cell, index) => visibleColumns[index].name === "modality");
      const rowBackgroundColor = modalityValue === "Specific Modality" ? 'lightgreen' : isSelected ? 'lightblue' : 'white';
    
      return (
        <tr
          key={"row" + rowIndex}
          onClick={handleRowClick}
          style={{
            backgroundColor: rowBackgroundColor,
            color: isSelected ? 'white' : 'black'
          }}
        >
          {visibleColumns.map((col, index) => {
            const cellValue = rowData[index];
            if (col.options?.display !== false) {
              return (
                <td key={index} style={{ textAlign: col.name === "modality" ? 'center' : 'left' }}>
                  {cellValue}
                </td>
              );
            }
            return null; 
          })}
        </tr>
      );
    },
    
    customToolbar: () => {
      const isMobile = window.innerWidth <= 600;
      const iconFontSize = isMobile && 16;
      if (selectedRowIndex < 0) return (<></>)
      return (
        <>
          <Tooltip title="Viewer" arrow>
            <IconButton
              color="primary"
              size="small"
              onClick={() => handleViewDetails()}
              disabled={selectedRowIndex < 0}
            >
              <VisibilityIcon style={{ color: '#FF9800', fontSize: iconFontSize }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="OHIF Viewer" arrow>
            <IconButton
              color="primary"
              size="small"
              onClick={() => handleOHIFViewDetails()}
              disabled={selectedRowIndex < 0}
            >
              <ViewModuleIcon style={{ color: '#1332a7', fontSize: iconFontSize }} />
            </IconButton>
          </Tooltip>
          {currentUser.roles && (currentUser.roles.includes('Admin')
            || currentUser.roles.includes('Client')) && (
              <Tooltip title="Referring Physician" arrow>
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => handleOpenReferringDialog()}
                >
                  <PersonIcon style={{ color: '#9c27b0', fontSize: iconFontSize }} />
                </IconButton>
              </Tooltip>
            )}
          {currentUser.roles && (currentUser.roles.includes('Admin')
            || currentUser.roles.includes('Specialist')
            || currentUser.roles.includes('Consultant')) && (
              <Tooltip title="Template" arrow>
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => handleDownloadTemplate()}
                >
                  <InsertDriveFileIcon style={{ color: '#1f77be', fontSize: iconFontSize }} />
                </IconButton>
              </Tooltip>
            )}
          {currentUser.roles && (currentUser.roles.includes('Admin')
            || currentUser.roles.includes('Consultant')
            || currentUser.roles.includes('Specialist')) && (
              <>
                <Tooltip title="Upload Report" arrow>
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => handleEdit()}
                  >
                    <CloudUploadIcon style={{ color: '#FF5722', fontSize: iconFontSize }} />
                  </IconButton>
                </Tooltip>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
              </>
            )}

          <>
            <Tooltip title="Download Image" arrow>
              <IconButton
                color="primary"
                size="small"
                onClick={() => handleDownloadImage()}
                disabled={loading.download} // Disable if download is in progress
              >
                {/* {loading.download ? (
                <CircularProgress size={24} />
              ) : ( */}
                <CloudDownloadIcon style={{ color: '#4CAF50', fontSize: iconFontSize }} />
                {/* )} */}
              </IconButton>
            </Tooltip>
          </>

          {currentUser.roles && (currentUser.roles.includes('Admin')
            || currentUser.roles.includes('Specialist')
            || currentUser.roles.includes('Consultant')) && (
              <Tooltip title="Download Word" arrow>
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => handleDownloadWordReport()}
                >
                  <DescriptionIcon style={{ color: '#2196F3', fontSize: iconFontSize }} />
                </IconButton>
              </Tooltip>
            )}
          {currentUser.roles && (currentUser.roles.includes('Admin')
            || currentUser.roles.includes('Client') || currentUser.roles.includes('Referring Physician')) && (
              <Tooltip title="Download PDF" arrow>
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => handleDownloadWordReport(true)}
                >
                  <PictureAsPdfIcon style={{ color: '#0B5394', fontSize: iconFontSize }} />
                </IconButton>
              </Tooltip>
            )}
          {currentUser.roles && (currentUser.roles.includes('Admin') || currentUser.roles.includes('Specialist')) && (
            <Tooltip title="Forward for review" arrow>
              <IconButton
                color="primary"
                size="small"
                onClick={() => handleComplete()}
                disabled={loading.complete} // Disable if complete is in progress
              >
                {loading.complete ? (
                  <CircularProgress size={24} />
                ) : (
                  <Forward style={{ color: '#3F51B5', fontSize: iconFontSize }} />
                )}
              </IconButton>
            </Tooltip>
          )}

          {currentUser.roles && (currentUser.roles.includes('Admin')
            || currentUser.roles.includes('Consultant')) && (
              <Tooltip title="Report Completed" arrow>
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => handleApprove()}
                  disabled={loading.approve} // Disable if approve is in progress
                >
                  <CheckCircleIcon style={{ color: '#4CAF50', fontSize: iconFontSize }} />
                </IconButton>
              </Tooltip>
            )}
          <Tooltip title="View Column" arrow>
            <IconButton
              color="primary"
              size="small"
              onClick={() => setOpenChangeColumnDialog(true)}
              disabled={selectedRowIndex < 0}
            >
              <ViewColumnIcon style={{ color: 'gray', fontSize: iconFontSize }} />
            </IconButton>
          </Tooltip>
        </>
      );
    },
  };
  return (
    <UTSLayout>
      {progressWord != "" && <LinearProgressWithLabel value="10" word={progressWord}></LinearProgressWithLabel>}
      <div style={{ opacity: ((progressWord != "") ? 0.5 : 1), pointerEvents: (progressWord != "") ? 'none' : 'auto' }}>
        <Grid container spacing={3} className={classes.my3} style={{ width: '100%' }}>
          <Grid item xs={12} md={2}>
            <TextField
              id="patientId"
              label="Patient ID"
              variant="outlined"
              fullWidth
              value={patientId}
              onChange={(e) => {
                setPatientId(e.target.value)
              }}
              autoComplete="off"
            />
          </Grid>
          {currentUser.roles && (currentUser.roles.includes('Admin')
            || currentUser.roles.includes('Client') || currentUser.roles.includes('Referring Physician')) && (
              <Grid item xs={12} md={2}>
                <TextField
                  label="Patient Name"
                  variant="outlined"
                  fullWidth
                  value={patientName}
                  onChange={(e) => setPatientName(e.target.value)}
                />
              </Grid>
            )}
          {/* <Grid item xs={12} md={2}>
          <TextField

            label="Accession Number"
            variant="outlined"
            fullWidth
            value={accessionNumber}
            onChange={(e) => setAccessionNumber(e.target.value)}
          />
        </Grid> */}
          <Grid item xs={12} md={2}>
            {/* <FormControl variant="outlined" fullWidth > */}
            <AddAutoComplete
              getValue={getModalityData}
              value={modality}
              data={modalities}
              variant="outlined" fullWidth
              displayName={'name'}
              label={'Modality'}
              multiple={true} />
            {/* </FormControl> */}
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              label="Start Date"
              variant="outlined"
              fullWidth
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              label="End Date"
              variant="outlined"
              fullWidth
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </Grid>

        </Grid>
        <Grid container spacing={3} className={classes.my3} style={{ width: '100%' }}>

          <Grid item xs={12}>
            <MUIDataTable
              style={{ width: '100%' }}
              title={"Studies List"}
              data={data}
              columns={visibleColumns}
              options={options}
              key={`data-table-${page}-${rowsPerPage}`}
            />
          </Grid>
        </Grid>
        {openUploadReportDialog &&
          <UploadReportDialog selectedStudy={selectedStudy} UploadReport={UploadReport} uploadFile={uploadFile} open={openUploadReportDialog} onClose={handleCloseUploadReportDialog}></UploadReportDialog>
        }
        {openApproveDialog &&
          <ApproveDialog selectedStudy={selectedStudy} open={openApproveDialog} setIsRefresh={setIsRefresh} isRefresh={isRefresh} setLoading={setLoading} onClose={handleCloseApproveDialog} setProgressWord={setProgressWord}></ApproveDialog>
        }
        {openCompleteDialog &&
          <CompleteDialog selectedStudy={selectedStudy} open={openCompleteDialog} setIsRefresh={setIsRefresh} isRefresh={isRefresh} setLoading={setLoading} onClose={handleCloseCompleteDialog} setProgressWord={setProgressWord}></CompleteDialog>
        }
        {openTemplateDialog &&
          <TemplateDialog open={openTemplateDialog} selectedStudy={selectedStudy} onClose={handleCloseTemplateDialog}></TemplateDialog>
        }
        {openReferringDialog && (
          <ReferringPhysicianDialog open={openReferringDialog} onClose={handleCloseReferringDialog} selectedStudy={selectedStudy} />
        )}
        {openChangeColumnDialog &&(
          <ChangeColumnDialog open={openChangeColumnDialog} onClose={handleCloseChangeColumnDialog} columns={visibleColumns}></ChangeColumnDialog>
        )}
      </div>
    </UTSLayout>
  );
};

export default AllStudiesPage;


